// @ts-nocheck

import { Dashboard } from '../api/types';
import useActiveOrganisation from './useActiveOrganisation';
import useActiveUser from './useActiveUser';
import useSyncedDocument from './useSyncedDocument';

const useDashboardStateSynced = (): [
  Dashboard,
  React.Dispatch<React.SetStateAction<Dashboard>>,
] => {
  const { activeUser } = useActiveUser();
  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();

  return useSyncedDocument({
    defaultValue: {
      flows: [],
      keywordMaps: [],
      assistants: [],
    },
    format: 'Map',
    type: 'dashboard',
    docId: activeOrganisation.organizationId,
  });
};

export default useDashboardStateSynced;

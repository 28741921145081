// @ts-nocheck

import { memo, useEffect, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';
import PreviewIcon from '@mui/icons-material/Preview';
import NodeTemplate from './NodeTemplate';
import getIconClass from '../../style/getIconClass';
import getNodeTypes from '../getNodeTypes';
import { Box, Typography } from '@mui/material';

interface CustomNodeProps extends NodeProps {
  data: {
    label: string;
    type: ReactFlowNodeType;
    question?: string;
    active?: boolean;
  };
}

const InterpretationNode = (props: CustomNodeProps) => {
  const [active, setActive] = useState(props.data.active);

  const nodeTypes = getNodeTypes();
  const nodeType = nodeTypes.find((type) => type.type === props.type);
  const IconClass = getIconClass(nodeType?.defaultIcon);

  useEffect(() => {
    setActive(props.data.active);
  }, [props.data.active]);

  return (
    <>
      <NodeTemplate active={props.data.active} node={props}></NodeTemplate>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </>
  );
};

export default InterpretationNode;

import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
  Divider,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import SignUpDialog from '../components/SignUpDialog';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    form.action = '/login/email';
    form.method = 'POST';
    form.submit();
  };

  const handleGoogleLogin = () => {
    // Handle Google login logic here
    window.location.href = '/login/google';
  };

  const handleOpenSignUpDialog = () => {
    setIsSignUpDialogOpen(true);
  };

  const handleCloseSignUpDialog = () => {
    setIsSignUpDialogOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ marginTop: 8, padding: 4 }}>
        <Typography component="h1" variant="h5" align="center">
          Welcome to Floness tools!
        </Typography>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Log In
          </Button>

          <Typography align="center">
            Don't have an account?{' '}
            <Button color="primary" onClick={handleOpenSignUpDialog}>
              Sign Up
            </Button>
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }}>OR</Divider>
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{ mb: 2 }}
        >
          Log in with Google
        </Button>
        <Typography align="center" variant="body2" sx={{ mt: 1 }}>
          Note: If you haven't signed up yet, you'll automatically create an
          account when logging in with Google.
        </Typography>
      </Paper>
      <SignUpDialog
        open={isSignUpDialogOpen}
        onClose={handleCloseSignUpDialog}
      />
    </Container>
  );
};

export default LoginPage;

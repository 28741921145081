import React, { useEffect, useState } from 'react';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Alert,
  IconButton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import type { BankAccount, BankTransaction } from '../api/bankAccountsAPI';
import AddIcon from '@mui/icons-material/Add';

type ASP = {
  name: string;
  country: string;
  logo: string;
  psu_types: string[];
  auth_methods: Array<{
    psu_type: string;
    credentials: Array<{
      name: string;
      title: string;
      required: boolean;
      description: string;
    }>;
    approach: string;
    hidden_method: boolean;
  }>;
};

const BankAccountsPage = () => {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [bankTransactions, setBankTransactions] = useState<BankTransaction[]>(
    []
  );
  const [selectedAccounts, setSelectedAccounts] = useState<Set<string>>(
    new Set()
  );
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshError, setRefreshError] = useState<string | null>(null);
  const { t } = useTranslation();
  const [activeOrganisation] = useActiveOrganisation();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [config, setConfig] = useState<Record<string, string>>({});
  const [asps, setAsps] = useState<ASP[]>([]);
  const [selectedAsp, setSelectedAsp] = useState<ASP | null>(null);
  const [selectedPsuType, setSelectedPsuType] = useState<string>('');
  const [openDialog, setOpenDialog] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAccount, setSelectedAccount] = useState<BankAccount | null>(
    null
  );

  const fetchBankAccounts = async () => {
    try {
      const response = await fetch(`/api/bankAccounts`, {
        headers: {
          'Content-Type': 'application/json',
          'X-Organization': activeOrganisation?.organizationId || '',
        },
      });

      if (response.status === 401) {
        const newAccessToken = await getAccessToken();
        setAccessToken(newAccessToken);
        if (newAccessToken) {
          return fetchBankAccounts();
        }
      }

      const data = await response.json();
      setBankAccounts(data);
      setSelectedAccounts(
        new Set(data.map((account: BankAccount) => account.id))
      );
    } catch (error) {
      console.error('Error fetching bank accounts:', error);
    }
  };

  const fetchBankTransactions = async () => {
    try {
      const fetchedTransactions: BankTransaction[] = [];
      await Promise.all(
        bankAccounts.map(async (bankAccount) => {
          const response = await fetch(`/api/bankAccounts/transactions`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-Organization': activeOrganisation?.organizationId || '',
            },
            body: JSON.stringify({
              accountIdentificationHash: bankAccount.id,
            }),
          });

          if (response.status === 401) {
            const newAccessToken = await getAccessToken();
            setAccessToken(newAccessToken);
            if (newAccessToken) {
              return fetchBankTransactions();
            }
          }

          const data = await response.json();
          fetchedTransactions.push(...data);
        })
      );
      setBankTransactions(fetchedTransactions);
    } catch (error) {
      console.error('Error fetching bank transactions:', error);
    }
  };

  const handleRefresh = async () => {
    if (!activeOrganisation?.organizationId) return;

    setRefreshing(true);
    setRefreshError(null);
    try {
      const response = await fetch(`/api/banking/enablebanking/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Organization': activeOrganisation.organizationId,
        },
      });

      const results = await response.json();
      if (results.find((result: any) => result.error)) {
        const errorResult = results.find((result: any) => result.error);
        setRefreshError(errorResult.error);
      } else {
        await fetchBankTransactions();
      }
    } catch (error) {
      setRefreshError('Failed to refresh bank data. Please try again later.');
    } finally {
      setRefreshing(false);
    }
  };

  const toggleAccountSelection = (accountId: string) => {
    setSelectedAccounts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(accountId)) {
        newSet.delete(accountId);
      } else {
        newSet.add(accountId);
      }
      return newSet;
    });
  };

  const fetchASPs = async () => {
    try {
      const response = await fetch(`/api/banking/enablebanking/asps`, {
        headers: {},
      });

      if (response.status === 401) {
        const newAccessToken = await getAccessToken();
        setAccessToken(newAccessToken);
        if (newAccessToken) {
          return fetchASPs();
        }
      }

      const data = await response.json();
      setAsps(data.aspsps || []);
    } catch (error) {
      console.error('Error fetching ASPs:', error);
    }
  };

  const handleConnectBank = async () => {
    if (!selectedAsp || !selectedPsuType || !activeOrganisation?.organizationId)
      return;

    setConnecting(true);
    try {
      const url = new URL(
        `/api/banking/enablebanking/start`,
        window.location.origin
      );
      url.searchParams.append('aspspName', selectedAsp.name);
      url.searchParams.append('country', selectedAsp.country);
      url.searchParams.append('psuType', selectedPsuType);
      url.searchParams.append(
        'organizationId',
        activeOrganisation.organizationId
      );
      url.searchParams.append(
        'redirectURL',
        'https://app.floness.com/api/banking/enablebanking/completed'
      );

      const response = await fetch(url.toString(), {
        headers: {},
      });

      if (response.status === 401) {
        const newAccessToken = await getAccessToken();
        setAccessToken(newAccessToken);
        if (newAccessToken) {
          return handleConnectBank();
        }
      }

      const { url: redirectUrl } = await response.json();
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error initiating bank connection:', error);
    } finally {
      setConnecting(false);
    }
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    account: BankAccount
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedAccount(account);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAccount(null);
  };

  const handleDeleteClick = async () => {
    handleMenuClose();
    if (!selectedAccount || !activeOrganisation?.organizationId) return;

    try {
      const response = await fetch(`/api/bankAccounts`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({ accountId: selectedAccount.id }),
      });

      if (response.ok) {
        setBankAccounts((prev) =>
          prev.filter((acc) => acc.id !== selectedAccount.id)
        );
        setSelectedAccounts((prev) => {
          const newSet = new Set(prev);
          newSet.delete(selectedAccount.id);
          return newSet;
        });
      } else {
        throw new Error('Failed to delete account');
      }
    } catch (error) {
      console.error('Error deleting bank account:', error);
    } finally {
      setSelectedAccount(null);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (activeOrganisation?.organizationId) {
        setLoading(true);
        try {
          await fetchBankAccounts();
        } catch (error) {
          console.error('Error loading bank accounts:', error);
        } finally {
          setLoading(false);
        }
      }
    };
    loadData();
  }, [activeOrganisation?.organizationId]);

  useEffect(() => {
    const loadTransactions = async () => {
      if (bankAccounts.length > 0 && activeOrganisation?.organizationId) {
        try {
          await fetchBankTransactions();
        } catch (error) {
          console.error('Error loading transactions:', error);
        }
      }
    };
    loadTransactions();
  }, [bankAccounts, activeOrganisation?.organizationId]);

  useEffect(() => {
    if (openDialog) {
      fetchASPs();
    }
  }, [openDialog]);

  const getAccessToken = async () => {
    try {
      const response = await fetch(
        `${config.REACT_APP_APP_SERVER_HOST}/access-token`,
        {
          method: 'GET',
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.accessToken;
      }
      return null;
    } catch (error) {
      console.error('Error getting access token:', error);
      return null;
    }
  };

  if (loading) {
    return (
      <Box>
        <HeaderBar
          currentPage={CurrentPages.Ledger as any}
          setCurrentPage={() => {}}
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <HeaderBar
        currentPage={CurrentPages.Ledger as any}
        setCurrentPage={() => {}}
      />

      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
          <Typography variant="h5">{t('Bank Accounts')}</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRefresh}
              disabled={refreshing}
              startIcon={<RefreshIcon />}
            >
              {refreshing ? t('Refreshing...') : t('Refresh')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
              startIcon={<AddIcon />}
            >
              {t('Connect Bank')}
            </Button>
          </Box>
        </Box>

        {refreshError && (
          <Snackbar
            open={!!refreshError}
            autoHideDuration={6000}
            onClose={() => setRefreshError(null)}
          >
            <Alert severity="error" onClose={() => setRefreshError(null)}>
              {refreshError}
            </Alert>
          </Snackbar>
        )}

        {bankAccounts.length === 0 ? (
          <Typography sx={{ textAlign: 'center', mt: 4 }}>
            {t('No bank accounts connected')}
          </Typography>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {bankAccounts.map((account) => (
              <Card key={account.id}>
                <CardContent
                  sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                >
                  <Checkbox
                    checked={selectedAccounts.has(account.id)}
                    onChange={() => toggleAccountSelection(account.id)}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h6">{account.aspspName}</Typography>
                    <Typography color="textSecondary">
                      {account.iban?.match(/.{1,4}/g)?.join(' ')}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('Valid until')}:{' '}
                      {new Date(account.validUntil).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <IconButton onClick={(e) => handleMenuClick(e, account)}>
                    <MoreHorizIcon />
                  </IconButton>
                </CardContent>
              </Card>
            ))}
          </Box>
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>{t('Connect Bank Account')}</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>{t('Account Type')}</InputLabel>
              <Select
                value={selectedPsuType}
                onChange={(e) => setSelectedPsuType(e.target.value)}
                label={t('Account Type')}
              >
                {Array.from(new Set(asps.flatMap((asp) => asp.psu_types))).map(
                  (type: string) => (
                    <MenuItem key={type} value={type}>
                      {t(type)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>

            <List>
              {asps
                .filter(
                  (asp) =>
                    !selectedPsuType || asp.psu_types.includes(selectedPsuType)
                )
                .map((asp) => (
                  <ListItem
                    key={asp.name}
                    button
                    selected={selectedAsp?.name === asp.name}
                    onClick={() => setSelectedAsp(asp)}
                  >
                    <ListItemAvatar>
                      <Avatar src={asp.logo} alt={asp.name}>
                        {asp.name[0]}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={asp.name} secondary={asp.country} />
                  </ListItem>
                ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>{t('Cancel')}</Button>
            <Button
              onClick={handleConnectBank}
              disabled={!selectedAsp || !selectedPsuType || connecting}
              variant="contained"
            >
              {connecting ? t('Connecting...') : t('Connect')}
            </Button>
          </DialogActions>
        </Dialog>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
            <DeleteIcon sx={{ mr: 1 }} />
            {t('Delete Account')}
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default BankAccountsPage;

// @ts-nocheck

import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import HeaderBar from '../header-bar/HeaderBar';
import { Fragment, useEffect, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import useDashboardStateSynced from '../collaboration/useDashboardStateSynced';
import { useNavigate } from 'react-router-dom';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';

import { CurrentPages } from '../header-bar/HeaderBar';

const toIcon = (voucher, status) => {
  switch (voucher.status) {
    case 'in_progress':
      if (voucher.createdAt * 1000 < Date.now() - 1000 * 60 * 60)
        return (
          <Box>
            <HourglassBottomIcon color="warning" sx={{ display: 'inline' }} />
            <Box sx={{ display: 'inline', position: 'absolute' }}>
              <Typography
                variant="body2"
                sx={{ display: 'inline', position: 'relative', top: '2px' }}
              >
                &nbsp;&gt;1h
              </Typography>
            </Box>
          </Box>
        );
      return <CircularProgress size={20} />;
    default:
      return <DoneIcon />;
  }
};

const VoucherPage = (props) => {
  const navigate = useNavigate();
  const [dashboard] = useDashboardStateSynced();

  const [vouchers, setVouchers] = useState([]);
  const [activeOrganisation] = useActiveOrganisation();
  useEffect(() => {
    const fetchVouchers = async () => {
      const response = await fetch('/api/vouchers', {
        headers: { 'X-Organisation-Id': activeOrganisation.organizationId },
      });
      const data = await response.json();

      data.sort((a, b) => b.createdAt - a.createdAt);
      setVouchers(data);
    };
    fetchVouchers();
  }, [setVouchers]);

  return (
    <Box>
      <HeaderBar currentPage={CurrentPages.History} />

      <Container maxWidth="lg" sx={{ marginTop: '30px' }}>
        <Typography variant="h5" sx={{ marginTop: '10px' }}>
          Vouchers
        </Typography>
        <Divider sx={{ marginTop: '10px', marginBottom: '40px' }} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Index</TableCell>
                <TableCell>Identifier</TableCell>
                <TableCell>Assistant</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vouchers.map((voucher, index) => {
                return (
                  <Fragment key={`voucher_tablerow_${index}`}>
                    <TableRow
                      onClick={() =>
                        navigate(
                          `/assistant/${voucher.assistantId}/threads/${voucher.threadId}`
                        )
                      }
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {voucher.id}
                        {voucher.description && (
                          <>
                            {' '}
                            <br />
                            {voucher.description}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        {
                          dashboard.assistants.find(
                            (assistant) => assistant.id === voucher.assistantId
                          )?.name
                        }
                      </TableCell>
                      <TableCell>{toIcon(voucher)}</TableCell>
                      <TableCell>
                        {new Date(voucher.createdAt).toLocaleDateString()}
                        <br />
                        {new Date(voucher.createdAt).toLocaleTimeString()}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default VoucherPage;

// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';

import NodeTemplate from './NodeTemplate';
import { TextField, Box } from '@mui/material';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import { useParams } from 'react-router-dom';
import setNodes from '../../collaboration/setNodes';
import { getNodes } from '../../collaboration/getNodes';
import getIconClass from '../../style/getIconClass';
import getNodeTypes from '../getNodeTypes';
import defaults from '../../collaboration/getDefaults';
import QuestionNode from './QuestionNode';

interface CustomNodeProps extends NodeProps {
  data: { label: string; type: ReactFlowNodeType; question?: string };
}

const AssistantNode = (props: CustomNodeProps) => {
  return <QuestionNode {...props} />;
};

export default AssistantNode;

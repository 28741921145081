// @ts-nocheck

import { useEffect, useState } from 'react';
import useActiveUser from './useActiveUser';
import useLocalStorage from './useLocalStorageForValue';
import { useNavigate } from 'react-router-dom';

let previousActiveOrganisation;

const useActiveOrganisation = () => {
  const navigate = useNavigate();
  const { activeUser } = useActiveUser();
  const personalOrganisation = {
    organizationId: activeUser.id,
    organizationName: 'Personal account for ' + activeUser.name,
  };
  const [previousOrganisation, setPreviousOrganisation] = useState(null);
  const [activeOrganisation, setActiveOrganisation] = useLocalStorage(
    'activeOrganisation23',
    personalOrganisation
  );

  return [
    activeOrganisation,
    (value) => {
      setActiveOrganisation(value);
      window.location.href = '/dashboard';
    },
  ];
};

export default useActiveOrganisation;

// @ts-nocheck

import { useState, useEffect } from 'react';

const fetchingUser = false;

type User = {
  name: string;
  id: string;
};

let activeUser: User | null = null;
let setUserFunction: (user: User) => void;

const fetchUser = async () => {
  console.error('fetchUser');
  try {
    const response = await fetch('/whoami');
    if (!response.ok) {
      const allowedAnonPaths = ['/login', '/signup'];
      if (!allowedAnonPaths.find((path) => window.location.href.endsWith(path)))
        window.location.href = '/login';

      // throw new Error('Failed to fetch user data');
    } else {
      const user = await response.json();
      activeUser = user;
    }
  } catch (err) {
    console.log(err);
    activeUser = null;
  }

  if (setUserFunction) setUserFunction(user);
};

await fetchUser();

const useActiveUser = () => {
  return { activeUser };

  const [user, setUser] = useState<User | null>(activeUser);
  setUserFunction = setUser;
  /*    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    console.log('useActiveUser', user);

    useEffect(() => {
        console.log('test');
        console.log('user changed to ', user);
        activeUser = user;
    }, [user]);

    useEffect(() => {
        if (fetchingUser) return;
        fetchingUser = true;

        if (activeUser) {
            setUser(activeUser);
        }

        const fetchUser = async () => {
            try {
                if (activeUser) {
                    setUser(activeUser);
                    return;
                }
                const response = await fetch('/whoami');
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                console.log('setUser', data);
                activeUser = data;
                setUser(data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An unknown error occurred');
            } finally {
                setLoading(false);
                fetchingUser = false;
            }
        };

        fetchUser();
    }, []);
*/
  return { user };
};

export default useActiveUser;

export { fetchUser };

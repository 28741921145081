// @ts-nocheck

import {
  Context,
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import * as Y from 'yjs';
import { YSweetProvider, createYjsProvider } from '@y-sweet/client';
import { ClientToken } from '@y-sweet/sdk';
import { useParams } from 'react-router-dom';
import getYDoc from './getYDoc';

type YDocContextType = {
  yDoc: Y.Doc | null;
  docId: string | null;
} | null;

const YDocContext = createContext(null) as Context<YDocContextType>;

export const fetchUser = async () =>
  (await (await fetch('/whoami')).json()) as {
    name: string;
    id: string;
    picture: string;
  };

const ydocs = {};

export const YDocProvider = (props: {
  children: React.ReactNode;
  docId?: string;
}) => {
  const [version, setVersion] = useState<number>(0);

  const { yDoc, loaded } = getYDoc(props.docId || '', () => {
    setVersion(version + 1);
  });

  return (
    <YDocContext.Provider value={{ yDoc, version, docId: props.docId, loaded }}>
      {props.children}
    </YDocContext.Provider>
  );

  /*


    const [docId, setDocId] = useState<string | null>(null);
    const [, setClientToken] = useState<ClientToken | null>(null);
    const [yDoc, setYDoc] = useState<Y.Doc | null>(ydocs[props.docId] || null);
    const [, setProvider] = useState<YSweetProvider | null>(null);
    const [, setMyColor] = useState<string | null>(null);

    const [loaded, setLoaded] = useState<boolean>(false);

    // if(!props.docId) return <YDocContext.Provider value={{ yDoc: null, docId: null }}>{props.children}</YDocContext.Provider>

    console.log('yDoc', props.docId);

    const init = useCallback(async () => {
        //         if(yDoc) return

        if (ydocs[props.docId]) {
            console.log('doc exists', props.docId);
            setYDoc(ydocs[props.docId]);
            return;
        }

        const initYDoc = new Y.Doc();

        ydocs[props.docId] = initYDoc;
        setYDoc(initYDoc);

        console.log('yDoc init', props.docId);
        const userColors = [
            '#30bced',
            '#6eeb83',
            '#ffbc42',
            '#ecd444',
            '#ee6352',
            '#9ac2c9',
            '#8acb88',
            '#1be7ff',
        ];

        const initDocId = props.docId || null;
        // const initDocId = props.docId || id || null;
        const initClientToken: ClientToken = await (
            await fetch(`/client-token/${initDocId}`)
        ).json();

        if (!initDocId) return;

        const initProvider = createYjsProvider(initYDoc, initClientToken, { disableBc: true });
        const initMyColor = userColors[Math.floor(Math.random() * userColors.length)];

        initProvider.awareness.setLocalStateField('user', {
            name: 'banana',
            color: initMyColor,
        });

        initProvider.on('synced', () => {
            console.log('synced');
            setLoaded(true);
        });

        setDocId(initDocId);

        setClientToken(initClientToken);
        setYDoc(initYDoc);
        setProvider(initProvider);
        setMyColor(initMyColor);
    }, [props.docId]);

    useEffect(() => {
        init();
    }, []);

    return (
        <YDocContext.Provider value={{ yDoc, docId, loaded }}>
            {props.children}
        </YDocContext.Provider>
    );

    */
};

const useYDoc = (props: { docId: string; onSynced: () => any }) => {
  const [version, setVersion] = useState<number>(0);

  if (!props) {
    debugger;
  }
  if (!props.docId) {
    debugger;
  }

  const { yDoc, loaded } = getYDoc(props.docId || '', async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    console.error('calling setVersion');
    setVersion(version + 1);
    console.log('setting version');
    //if(props.onSynced) {
    // props.onSynced()
    // }
  });

  // console.log('useYDoc',version, loaded, props.docId, yDoc, loaded);

  return { yDoc, docId: props.docId, version, loaded };

  /*

    const context = useContext(YDocContext);
    if (!context) {
        throw new Error('useYDoc must be used within a YDocProvider');
    }
    return context;
    */
};

export default useYDoc;

const iconStyle = {
  background: 'white',
  padding: '1px',
  cursor: 'pointer',
  borderRadius: '20px',
  position: 'relative',
  top: '7px',
  marginTop: '-10px',
};

export default iconStyle;

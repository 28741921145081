enum ReactFlowNodeTypes {
  START = 'StartNode',
  TERMINAL = 'TerminalNode',
  DEFAULT = 'default',
  CUSTOM = 'CustomNode',
  ALERT = 'AlertNode',
  INTERPRETATION = 'InterpretationNode',
  ASSISTANT = 'AssistantNode',
  PROGRAMMABLE = 'ProgrammableNode',
  USER = 'UserNode',
  BOOKKEEPING_ACCOUNT = 'BookkeepingAccountNode',
  SPLIT_LINES = 'SplitLinesNode',
  KEYWORD_MAP_BOOKKEEPING_ACCOUNT = 'KeywordMapBookkeepingAccountNode',
  KEYWORD_MAP_FILTER = 'KeywordMapFilterNode',
  KEYWORD_MAP_TAG_FILTER = 'KeywordMapTagNode',
  GROUP_NODE = 'GroupNode',
  GROUP_INPUT_NODE = 'GroupInputNode',
  GROUP_OUTPUT_NODE = 'GroupOutputNode',
  GROUP_INSTANCE_NODE = 'GroupInstanceNode',
}

export default ReactFlowNodeTypes;

// @ts-nocheck

const defaults = {};

const setDefault = (key, values, setValues) => {
  defaults[key] = values;
  defaults[`set${key[0].toUpperCase()}${key.substring(1)}`] = setValues;
};

export default defaults;

export { setDefault, defaults };

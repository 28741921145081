import React, { useState, useEffect } from 'react';
import {
  deserializeChartOfAccounts,
  ChartOfAccounts,
  serializeAccount,
} from '@floness/accounting/browser';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  IconButton,
  Autocomplete,
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import { useParams, useNavigate } from 'react-router-dom';

interface Posting {
  id: string;
  amount: number;
  accountId: string;
  timestamp: number;
  modifiedAt: number;
  createdAt: number;
  dimensions: any[];
  postingReferences: any[];
}

interface VoucherReference {
  id: string;
  type: string;
  value: string;
  timestamp: number;
  modifiedAt: number;
  createdAt: number;
}

interface Voucher {
  id: string;
  description: string;
  timestamp: number;
  modifiedAt: number;
  createdAt: number;
  postings: Posting[];
  voucherReferences: VoucherReference[];
  path: string | null;
  type: string | null;
  origin: string | null;
  originalContextType: string | null;
  originalContextId: string | null;
  hash: string | null;
  contentType: string | null;
}

interface Account {
  id: string;
  number: string;
  name: string;
}

const formatAmount = (amount: number): number => amount / 100;
const parseAmount = (amount: number): number => Math.round(amount * 100);

const VoucherDetailsPage: React.FC = () => {
  const { voucherId } = useParams<{ voucherId: string }>();
  const [activeOrganisation] = useActiveOrganisation();
  const [voucher, setVoucher] = useState<Voucher | null>(null);
  const [postings, setPostings] = useState<Posting[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [selectedBlobUrl, setSelectedBlobUrl] = useState<string | null>(null);
  const [pdfBase64Map, setPdfBase64Map] = useState<Record<string, string>>({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [voucherResponse, accountsResponse] = await Promise.all([
          fetch(`/api/accounting/vouchers/${voucherId}`, {
            headers: {
              'X-Organization': activeOrganisation.organizationId,
            },
          }),
          fetch('/api/accounting/chart-of-accounts', {
            headers: {
              'X-Organization': activeOrganisation.organizationId,
            },
          }),
        ]);

        const [voucherData, accountsData] = await Promise.all([
          voucherResponse.json(),
          accountsResponse.json(),
        ]);

        setVoucher(voucherData);
        setPostings(voucherData.postings || []);

        const { list } = deserializeChartOfAccounts(accountsData.accounts);
        setAccounts(list);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (voucherId) {
      fetchData();
    }
  }, [voucherId, activeOrganisation]);

  const handlePostingChange = (
    index: number,
    field: keyof Posting,
    value: any
  ) => {
    const updatedPostings = [...postings];
    updatedPostings[index] = {
      ...updatedPostings[index],
      [field]: value,
    };
    setPostings(updatedPostings);
  };

  const handleAddPosting = () => {
    const newPosting: Posting = {
      id: `posting_${Math.random().toString(36).substr(2, 24)}`,
      amount: 0,
      accountId: '',
      timestamp: Date.now(),
      modifiedAt: Date.now(),
      createdAt: Date.now(),
      dimensions: [],
      postingReferences: [],
    };
    setPostings([...postings, newPosting]);
  };

  const handleDeletePosting = (index: number) => {
    const updatedPostings = postings.filter((_, i) => i !== index);
    setPostings(updatedPostings);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(`/api/accounting/vouchers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({
          ...voucher,
          postings,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to save voucher');
      }
    } catch (error) {
      console.error('Error saving voucher:', error);
    }
  };

  const getBlobReferences = () => {
    return (
      voucher?.voucherReferences?.filter((ref) => ref.type === 'blob') || []
    );
  };

  useEffect(() => {
    const fetchPDFs = async () => {
      const blobRefs = getBlobReferences();
      for (const ref of blobRefs) {
        const isPDF =
          ref.value.toLowerCase().endsWith('.pdf') ||
          ref.contentType === 'application/pdf';
        if (!isPDF) continue;

        const documentUrl = `${ref.value.replace('s3://', '/api/blob/')}?redirect=true`;
        try {
          const response = await fetch(documentUrl);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setPdfBase64Map((prev) => ({
              ...prev,
              [ref.id]: base64data,
            }));
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error('Error fetching PDF:', error);
        }
      }
    };

    if (voucher) {
      fetchPDFs();
    }
  }, [voucher]);

  const renderDocument = (ref: VoucherReference) => {
    const isPDF =
      ref.value.toLowerCase().endsWith('.pdf') ||
      ref.contentType === 'application/pdf';
    const documentUrl = `${ref.value.replace('s3://', '/api/blob/')}?redirect=true`;

    if (isPDF) {
      return (
        <>
          {pdfBase64Map[ref.id] && (
            <iframe
              src={pdfBase64Map[ref.id] + '#toolbar=0'}
              title="Voucher PDF document"
              style={{ width: '100%', height: '600px', border: 'none' }}
            ></iframe>
          )}
        </>
      );
    }

    return (
      <img
        src={documentUrl}
        alt="Voucher document"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    );
  };

  const handleDelete = async () => {
    if (
      !voucher ||
      !window.confirm(
        'Are you sure you want to delete this voucher and all its postings?'
      )
    ) {
      return;
    }

    try {
      const response = await fetch(`/api/accounting/vouchers/${voucherId}`, {
        method: 'DELETE',
        headers: {
          'X-Organization': activeOrganisation.organizationId,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete voucher');
      }

      navigate('/history');
    } catch (error) {
      console.error('Error deleting voucher:', error);
    }
  };

  if (!voucher) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <HeaderBar currentPage={CurrentPages.History} />
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="h4">Voucher Details</Typography>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleDelete}
          >
            Delete Voucher
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, mb: 2, minHeight: '400px' }}>
              <Typography variant="h6" gutterBottom>
                Document Preview
              </Typography>
              {getBlobReferences().map((ref) => (
                <Box key={ref.id} sx={{ mb: 2 }}>
                  {renderDocument(ref)}
                </Box>
              ))}
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ p: 2, mb: 2 }}>
              <Typography variant="h6">Description</Typography>
              <Typography>{voucher.description}</Typography>
              <Typography variant="body2" color="textSecondary">
                Created: {new Date(voucher.createdAt).toLocaleString()}
              </Typography>
            </Paper>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account ID</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {postings.map((posting, index) => (
                    <TableRow key={posting.id}>
                      <TableCell>
                        <Autocomplete
                          value={
                            accounts.find(
                              (acc) => acc.id === posting.accountId
                            ) || null
                          }
                          onChange={(_, newValue) =>
                            handlePostingChange(
                              index,
                              'accountId',
                              newValue?.id || ''
                            )
                          }
                          options={accounts}
                          getOptionLabel={(option) =>
                            `${option.number} - ${option.name}`
                          }
                          renderInput={(params) => (
                            <TextField {...params} size="small" />
                          )}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={formatAmount(posting.amount)}
                          onChange={(e) =>
                            handlePostingChange(
                              index,
                              'amount',
                              parseAmount(parseFloat(e.target.value))
                            )
                          }
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeletePosting(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddPosting}
              >
                Add Posting
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default VoucherDetailsPage;

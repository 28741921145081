// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';

import NodeTemplate from './NodeTemplate';
import { Box, Typography } from '@mui/material';
import getIconClass from '../../style/getIconClass';
import getNodeTypes from '../getNodeTypes';

interface CustomNodeProps extends NodeProps {
  data: { label: string; type: ReactFlowNodeType; question?: string };
}

const KeywordMapFilterNode = (props: CustomNodeProps) => {
  const nodeTypes = getNodeTypes();
  const nodeType = nodeTypes.find((type) => type.type === props.type);

  const IconClass = getIconClass(nodeType?.defaultIcon);
  return (
    <>
      <NodeTemplate active={props.data.active} node={props}>
        {props.data.question && (
          <p
            style={{
              fontWeight: 400,
              fontSize: '0.75rem',
              margin: '0.5rem 0 0 0',
              padding: 0,
              maxWidth: '15rem',
            }}
          >
            {props.data.question}
          </p>
        )}
      </NodeTemplate>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="target" position={Position.Top} />
    </>
  );
};

export default KeywordMapFilterNode;

import {
  Account,
  AccountGroup,
  ChartOfAccounts,
  SerializedAccount,
} from './types';

function deserializeChartOfAccounts(listedAccounts: ListedAccount[]) {
  const tree: AccountGroup[] = [];
  const list: Account[] = [];
  const accountsById: { [key: string]: Account } = {};

  const pendingAccounts = listedAccounts;

  const processListedAccount = (listedAccount) => {
    const {
      id,
      name,
      accounts,
      subgroups,
      number: listedAccountNumber,
      parent,
    } = listedAccount;

    if (parent && !accountsById[parent]) {
      console.log(listedAccount.name);

      pendingAccounts.push(listedAccount);
      return;
    }

    listedAccount.accounts = [];
    listedAccount.subgroups = [];

    const account = listedAccount as Account;
    account.parent = accountsById[parent];
    accountsById[id] = account;

    account.tags =
      typeof listedAccount.tags == 'string'
        ? listedAccount.tags?.split(',')
        : listedAccount.tags;
    account.tags = [
      ...new Set([...(account.tags || []), ...(account.parent?.tags || [])]),
    ].filter((tag) => tag !== '');

    account.vats =
      typeof listedAccount.vats == 'string'
        ? JSON.parse(listedAccount.vats)
        : listedAccount.vats || [];

    const parentVats = account.parent?.vats || [];

    const derivedParentVats = parentVats.map((vat) => {
      return { ...vat, derivedFromParent: true };
    });
    account.vats = [...(account.vats || []), ...derivedParentVats];

    list.push(account);

    if (!parent) {
      tree.push(account);
    } else {
      if (listedAccountNumber) {
        account.parent.accounts?.push(account);
      } else {
        account.parent.subgroups?.push(account);
      }
    }
  };

  // Validate that all parents exist
  for (const account of listedAccounts) {
    if (
      account.parent &&
      !listedAccounts.some((a) => a.id === account.parent)
    ) {
      throw new Error(
        `Parent account ${account.parent} not found for account ${account.id} (${account.name})`
      );
    }
  }

  listedAccounts.forEach((account) => {
    if (account.id == account.parent) {
      account.parent = null;
    }
  });
  // Sort accounts by depth (parents first)
  // Calculate depths first
  listedAccounts.forEach((account) => {
    let depth = 0;
    let current = account;
    while (current.parent) {
      depth++;
      current = listedAccounts.find((acc) => acc.id === current.parent);
      if (depth > 20) {
        console.log(current.name);
        console.log(current.parent);
        console.log(current.id);
      }
      if (!current) break;
    }
    account._depth = depth;
  });

  // Sort by depth
  listedAccounts.sort((a, b) => a._depth - b._depth);

  const startTime = performance.now();

  while (pendingAccounts.length > 0) {
    if (performance.now() - startTime > 10000) {
      throw new Error(
        'Chart of accounts processing timed out after 10 seconds'
      );
    }
    processListedAccount(pendingAccounts.shift());
  }

  // Add min/max account numbers recursively
  const addMinMaxNumbers = (node: Account) => {
    let min = node.number ? parseInt(node.number) : Infinity;
    let max = node.number ? parseInt(node.number) : -Infinity;

    // Process subgroups
    if (node.subgroups) {
      for (const subgroup of node.subgroups) {
        addMinMaxNumbers(subgroup);
        min = Math.min(min, subgroup.minAccountNumber);
        max = Math.max(max, subgroup.maxAccountNumber);
      }
    }

    // Process accounts
    if (node.accounts) {
      for (const account of node.accounts) {
        addMinMaxNumbers(account);
        if (account.number) {
          min = Math.min(min, parseInt(account.number));
          max = Math.max(max, parseInt(account.number));
        }
      }
    }

    if (node.subgroups) {
      node.subgroups.sort((a, b) => a.minAccountNumber - b.minAccountNumber);
    }

    node.minAccountNumber = min;
    node.maxAccountNumber = max;
  };

  // Add asset/liability flags based on account names and propagate to children
  const addAssetLiabilityFlags = (
    node: Account,
    isAsset?: boolean,
    isLiability?: boolean
  ) => {
    const normalizedName = node.name?.toLowerCase().replace(/\s+/g, '');

    // Check name or inherit from parent
    if (normalizedName.match(/^vastaava[a-z]?/)) {
      isAsset = true;
    } else if (normalizedName.match(/^vastattava[a-z]?/)) {
      isLiability = true;
    }

    // Set flags if they were determined
    if (isAsset) {
      (node as any).asset = true;
    }
    if (isLiability) {
      (node as any).liability = true;
    }

    // Process subgroups with inherited flags
    if (node.subgroups) {
      for (const subgroup of node.subgroups) {
        addAssetLiabilityFlags(subgroup, isAsset, isLiability);
      }
    }

    // Process accounts with inherited flags
    if (node.accounts) {
      for (const account of node.accounts) {
        addAssetLiabilityFlags(account, isAsset, isLiability);
      }
    }
  };

  // Process all root nodes
  for (const node of tree) {
    addMinMaxNumbers(node);
    addAssetLiabilityFlags(node);
  }

  return { list, tree };
}

export { deserializeChartOfAccounts };
export default deserializeChartOfAccounts;

// @ts-nocheck

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Switch,
} from '@mui/material';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import { Fragment, useEffect, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import useDashboardStateSynced from '../collaboration/useDashboardStateSynced';
import { useNavigate } from 'react-router-dom';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import useActiveUser from '../collaboration/useActiveUser';

// Add type definition
type BankStatementFrequency = 'none' | 'daily' | 'monthly';

const OrganisationSettingsPage = (props) => {
  const [activeOrganisation, setActiveOrganisation] = useActiveOrganisation();
  const { activeUser } = useActiveUser();

  const [organisationMembers, setOrganisationMembers] = useState([]);
  const [apiKey, setApiKey] = useState('');
  const [accountantEmail, setAccountantEmail] = useState('');
  const [bankStatementFrequency, setBankStatementFrequency] =
    useState<BankStatementFrequency>('none');
  const [sendAllVouchers, setSendAllVouchers] = useState(false);
  const [accountingStartDate, setAccountingStartDate] = useState<Date | null>(
    null
  );
  const [accountingStartDateInput, setAccountingStartDateInput] = useState('');
  const [netvisorUserId, setNetvisorUserId] = useState('');
  const [netvisorUserKey, setNetvisorUserKey] = useState('');
  const [netvisorOrganizationId, setNetvisorOrganizationId] = useState('');
  const [fennoaUsername, setFennoaUsername] = useState('');
  const [fennoaApiKey, setFennoaApiKey] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    const fetchOrganisationMembers = async () => {
      const response = await fetch(
        `/api/organizations/${activeOrganisation.organizationId}/members`
      );
      const data = await response.json();

      data.sort((a, b) => b.createdAt - a.createdAt);
      setOrganisationMembers(data);
    };
    fetchOrganisationMembers();
  }, [setOrganisationMembers]);

  useEffect(() => {
    const fetchApiKey = async () => {
      const response = await fetch(
        `/api/organizations/${activeOrganisation.organizationId}/apiKey`
      );
      const data = await response.json();

      setApiKey(data.apiKey);
    };
    fetchApiKey();
  }, [setApiKey]);

  const [newUserId, setNewUserId] = useState('');

  const addUser = async () => {
    setNewUserId('');

    const id = newUserId.trim();

    if (id.length == 0) {
      alert('empty user id');
      return;
    }

    setNewUserId('');

    const response = await fetch(
      `/api/organizations/${activeOrganisation.organizationId}/userRights/${id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rights: 'member' }),
      }
    );

    const member = await response.json();

    setOrganisationMembers([...organisationMembers, member]);
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      if (!activeOrganisation?.organizationId) return;

      try {
        setIsLoading(true);

        const headers = {
          'x-organization': activeOrganisation.organizationId,
        };

        const [
          preferencesResponse,
          emailResponse,
          netvisorResponse,
          fennoaResponse,
        ] = await Promise.all([
          fetch(`/api/accounting/accountant/preferences`, { headers }),
          fetch(`/api/accounting/accountant/email`, { headers }),
          fetch(`/api/accounting/integrations/netvisor`, { headers }),
          fetch(`/api/accounting/integrations/fennoa`, { headers }),
        ]);

        if (preferencesResponse.ok) {
          const { preferences } = (await preferencesResponse.json()) || {
            preferences: {},
          };
          setBankStatementFrequency(
            preferences.bankStatementFrequency || 'none'
          );
          setSendAllVouchers(preferences.sendAllVouchers || false);
          if (preferences.accountingStartDate) {
            setAccountingStartDate(preferences.accountingStartDate);
            setAccountingStartDateInput(preferences.accountingStartDate);
          }
        }

        if (emailResponse.ok) {
          const { email } = (await emailResponse.json()) || {};
          setAccountantEmail(email);
        }

        if (netvisorResponse.ok) {
          const netvisorData = await netvisorResponse.json();
          setNetvisorUserId(netvisorData.userId || '');
          setNetvisorUserKey(netvisorData.userKey || '');
          setNetvisorOrganizationId(netvisorData.organizationId || '');
        }

        if (fennoaResponse.ok) {
          const fennoaData = await fennoaResponse.json();
          setFennoaUsername(fennoaData.username || '');
          setFennoaApiKey(fennoaData.apiKey || '');
        }
        setTimeout(() => {
          setInitialLoadDone(true);
        });
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [activeOrganisation?.organizationId]);

  const saveAccountantEmail = async (email: string) => {
    try {
      await fetch(`/api/accounting/accountant/email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({ email }),
      });
    } catch (error) {
      console.error('Error saving accountant email:', error);
    }
  };

  const saveAccountantPreferences = async () => {
    try {
      await fetch(`/api/accounting/accountant/preferences`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({
          preferences: {
            bankStatementFrequency,
            sendAllVouchers,
            accountingStartDate,
          },
        }),
      });
    } catch (error) {
      console.error('Error saving accountant preferences:', error);
    }
  };

  useEffect(() => {
    if (initialLoadDone) saveAccountantPreferences();
  }, [
    initialLoadDone,
    JSON.stringify({
      bankStatementFrequency,
      sendAllVouchers,
      accountingStartDate,
    }),
  ]);

  const saveNetvisorCredentials = async () => {
    try {
      await fetch(`/api/accounting/integrations/netvisor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({
          userId: netvisorUserId,
          userKey: netvisorUserKey,
          organizationId: netvisorOrganizationId,
        }),
      });
    } catch (error) {
      console.error('Error saving Netvisor credentials:', error);
    }
  };

  const saveFennoaCredentials = async () => {
    try {
      await fetch(`/api/accounting/integrations/fennoa`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-organization': activeOrganisation.organizationId,
        },
        body: JSON.stringify({
          username: fennoaUsername,
          apiKey: fennoaApiKey,
        }),
      });
    } catch (error) {
      console.error('Error saving Fennoa credentials:', error);
    }
  };

  return (
    <Box>
      <HeaderBar currentPage={CurrentPages.Settings} />
      <Container maxWidth="md" sx={{ marginTop: '30px' }}>
        <Typography variant="h5" sx={{ marginTop: '10px' }}>
          Organisation settings
        </Typography>
        <Divider sx={{ marginTop: '10px', marginBottom: '40px' }} />

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Accountant Settings Section */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Accountant Settings
              </Typography>

              <TextField
                fullWidth
                label="Accountant Email"
                value={accountantEmail}
                onChange={async (e) => {
                  setAccountantEmail(e.target.value);
                  try {
                    await saveAccountantEmail(e.target.value);
                  } catch (error) {
                    console.error('Error saving accountant email:', error);
                  }
                }}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" gutterBottom>
                Bank Statement Frequency
              </Typography>

              {/* Bank Statement Frequency Options */}
              <Box sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Switch
                    checked={bankStatementFrequency === 'none'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBankStatementFrequency('none');
                      }
                    }}
                  />
                  <Typography>Do not send bank statements</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Switch
                    checked={bankStatementFrequency === 'daily'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBankStatementFrequency('daily');
                      }
                    }}
                  />
                  <Typography>Send bank statements daily</Typography>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Switch
                    checked={bankStatementFrequency === 'monthly'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBankStatementFrequency('monthly');
                      }
                    }}
                  />
                  <Typography>Send bank statements monthly</Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Switch
                  checked={sendAllVouchers}
                  onChange={(e) => {
                    setSendAllVouchers(e.target.checked);
                  }}
                />
                <Typography>Send all vouchers</Typography>
              </Box>

              <TextField
                type="date"
                label="Accounting Start Date"
                value={accountingStartDateInput}
                onChange={(e) => {
                  setAccountingStartDateInput(e.target.value);
                  const date = new Date(e.target.value);
                  if (!isNaN(date.getTime())) {
                    setAccountingStartDate(e.target.value);
                  }
                }}
                fullWidth
              />
            </Paper>

            {/* Netvisor Integration Section */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Netvisor Integration
              </Typography>

              <TextField
                fullWidth
                label="Organization ID"
                value={netvisorOrganizationId}
                onChange={(e) => setNetvisorOrganizationId(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="User ID"
                value={netvisorUserId}
                onChange={(e) => setNetvisorUserId(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="User Key"
                type="password"
                value={netvisorUserKey}
                onChange={(e) => setNetvisorUserKey(e.target.value)}
                sx={{ mb: 2 }}
              />

              <Button variant="contained" onClick={saveNetvisorCredentials}>
                Save Netvisor Settings
              </Button>
            </Paper>

            {/* Fennoa Integration Section */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Fennoa Integration
              </Typography>

              <TextField
                fullWidth
                label="Username"
                value={fennoaUsername}
                onChange={(e) => setFennoaUsername(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="API Key"
                type="password"
                value={fennoaApiKey}
                onChange={(e) => setFennoaApiKey(e.target.value)}
                sx={{ mb: 2 }}
              />

              <Button variant="contained" onClick={saveFennoaCredentials}>
                Save Fennoa Settings
              </Button>
            </Paper>

            {/* Existing Organization Members Section */}
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Organisation members
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>User identifier</TableCell>
                      <TableCell>Rights</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {organisationMembers.map((member, index) => (
                      <TableRow key={index + 'frewfew'}>
                        <TableCell>{member.name}</TableCell>
                        <TableCell>{member.userId}</TableCell>
                        <TableCell>{member.rights}</TableCell>
                      </TableRow>
                    ))}

                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <TextField
                          placeholder="User identifier"
                          sx={{ width: '100%' }}
                          value={newUserId}
                          onChange={(event) => setNewUserId(event.target.value)}
                          onKeyDown={async (event) => {
                            if (event.key === 'Enter') {
                              // create new member

                              addUser();
                            }
                          }}
                        ></TextField>
                      </TableCell>
                      <TableCell>
                        <Button onClick={() => addUser()}>Add</Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Container>
    </Box>
  );
};

export default OrganisationSettingsPage;

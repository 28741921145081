// @ts-nocheck

import { memo } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';
import PersonIcon from '@mui/icons-material/Person';
import NodeTemplate from './NodeTemplate';
import getIconClass from '../../style/getIconClass';
import getNodeTypes from '../getNodeTypes';
import { useParams } from 'react-router-dom';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import { Box, TextField, Typography } from '@mui/material';

interface CustomNodeProps extends NodeProps {
  data: { label: string; type: ReactFlowNodeType; question?: string };
}

const QuestionNode = (props: CustomNodeProps) => {
  const nodeTypes = getNodeTypes();
  const nodeType = nodeTypes.find((type) => type.type === props.type);

  const { flowId } = useParams();
  const [nodes, setNodes] = useNodesStateSynced({ flowId });

  const IconClass = getIconClass(nodeType?.defaultIcon);
  return (
    <>
      <NodeTemplate active={props.data.active} node={props}>
        <Box>
          <TextField
            size={'small'}
            variant={'standard'}
            onBlur={() => {}}
            value={props.data.question}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            sx={{ width: '320px', marginTop: '5px' }}
            onChange={(e) => {
              e.stopPropagation();
              props.data.question = e.target.value;
              setNodes([...nodes]);

              // setNodes([...nodes]);
            }}
          >
            {' '}
          </TextField>
        </Box>
      </NodeTemplate>
      <Handle type="source" position={Position.Bottom} />

      <Handle type="target" position={Position.Top} />
    </>
  );
};

export default QuestionNode;

// @ts-nocheck

import {
  Context,
  useCallback,
  useContext,
  useEffect,
  useState,
  createContext,
} from 'react';
import * as Y from 'yjs';
import { YSweetProvider, createYjsProvider } from '@y-sweet/client';
import { ClientToken } from '@y-sweet/sdk';

import { set } from 'lodash';

type YDocContextType = {
  yDoc: Y.Doc | null;
  docId: string | null;
} | null;

const YDocContext = createContext(null) as Context<YDocContextType>;

const unloadedYdocs = {};
const ydocs = {};
const fetchingYdocs = {};

async function sha256(input) {
  // Convert the input string to a Uint8Array
  const encoder = new TextEncoder();
  const data = encoder.encode(input);

  // Use the SubtleCrypto.digest method to compute the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);

  // Convert the hash buffer to a byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert bytes to a hex string
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
}

function useYDocV2(params: {
  organizationId;
  docId: string;
  onLoaded: () => any;
}): { organizationId: string; yDoc: Y.Doc; loaded: boolean; docId: string } {
  // const [loaded, setLoaded] = useState<boolean>(false);

  if (!params.organizationId) {
    debugger;
    throw new Error('organizationId is required');
  }
  /*

    if(!params.docId) {
        return { loaded: false, docId, yDoc: new Y.Doc()}
    }*/

  const key = `${params.organizationId}/${params.docId}`;
  const finalDocId = sha256(key);
  const id = params.docId;

  if (ydocs[id]) return { loaded: true, id, yDoc: ydocs[id] };

  if (!unloadedYdocs[id]) unloadedYdocs[id] = new Y.Doc();

  initYDoc(params.organizationId, id).then(async () => {
    params.onLoaded(id);
  });

  return { loaded: false, docId: id, yDoc: unloadedYdocs[id] };
}

async function initYDoc(organizationId: string, id: string): Promise<Y.Doc> {
  if (fetchingYdocs[id]) {
    await new Promise((resolve) => fetchingYdocs[id].push(resolve));

    return ydocs[id];
  }

  fetchingYdocs[id] = [];

  const initClientToken: ClientToken = await (
    await fetch(`/client-token/${id}`, {
      headers: {
        'X-Organisation-Id': organizationId,
      },
    })
  ).json();

  const ydoc = unloadedYdocs[id];
  const provider = createYjsProvider(ydoc, initClientToken, {
    disableBc: true,
  });

  await new Promise((resolve) =>
    provider.on('synced', () => {
      ydocs[id] = ydoc;
      resolve();
    })
  );

  return ydocs[id];
}

export default useYDocV2;

import {
  Account,
  AccountGroup,
  ChartOfAccounts,
  SerializedAccount,
  ListedAccount,
} from './types';

export function serializeAccount(account: ListedAccount): SerializedAccount {
  return {
    ...account,
    accounts:
      typeof account.accounts === 'object'
        ? account.accounts.map((a) => a.id).join(',')
        : account.accounts?.join(',') || '',
    subgroups:
      typeof account.subgroups === 'object'
        ? account.subgroups.map((s) => s.id).join(',')
        : account.subgroups?.join(',') || '',
    tags: account.tags?.join(',') || '',
    parent: account.parent?.id,
    vats: JSON.stringify(
      (account.vats || []).filter((vat) => !vat.derivedFromParent)
    ),
  };
}

export default serializeAccount;

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';

interface Customer {
  customerId: string;
  customerName: string;
  tags: string[];
}

const CustomersPage: React.FC = () => {
  const { t } = useTranslation();
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch('/api/customers');
        if (!response.ok) {
          throw new Error('Failed to fetch customers');
        }
        const data = await response.json();
        setCustomers(data);
      } catch (error) {
        console.error('Error fetching customers:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    fetchCustomers();
  }, []);

  return (
    <>
      <HeaderBar
        currentPage={CurrentPages.Customers}
        setCurrentPage={() => {}}
      />
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('Customers')}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} container>
            <Grid item xs={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t('Customer ID')}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t('Name')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                {t('Tags')}
              </Typography>
            </Grid>
          </Grid>
          {customers.map((customer) => (
            <Grid item xs={12} container key={customer.customerId}>
              <Grid item xs={3}>
                <Typography>{customer.customerId}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>{customer.customerName}</Typography>
              </Grid>
              <Grid item xs={6}>
                {customer.tags.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    size="small"
                    sx={{ mr: 0.5, mb: 0.5 }}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default CustomersPage;

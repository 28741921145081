// @ts-nocheck

import { memo, useCallback, useEffect, useState } from 'react';
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import NodeTemplate from './NodeTemplate';
import { useParams } from 'react-router-dom';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import useEdgesStateSynced from '../../collaboration/useEdgesStateSynced';

interface CustomNodeProps extends NodeProps {
  data: { label: string; type: ReactFlowNodeType; question?: string };
}

const LayoutNode = (props: CustomNodeProps) => {
  const { flowId } = useParams();
  const [nodes, setNodes] = useNodesStateSynced({ flowId });
  const [edges, setEdges] = useEdgesStateSynced({ flowId });

  const [direction, setDirection] = useState(props.data.direction || 'up');

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (props.data.direction !== direction)
      setDirection(props.data.direction || 'up');
  }, [props.data.direction]);

  const turn = () => {
    let newDirection;
    if (direction === 'up') {
      newDirection = 'right';
    } else if (direction == 'right') {
      newDirection = 'down';
    } else if (direction == 'down') {
      newDirection = 'left';
    } else if (direction == 'left') {
      newDirection = 'up';
    }

    props.data.rotation += Math.PI / 2;
    console.log(edges);

    setDirection(newDirection);
    props.data.direction = newDirection;

    // setNodes([...nodes]);
    updateNodeInternals(props.id);
  };

  let sourcePosition,
    targetPosition,
    iconRotation = 0;

  switch (direction) {
    case 'right':
      sourcePosition = Position.Left;
      targetPosition = Position.Right;
      iconRotation = Math.PI / 2;
      break;
    case 'down':
      sourcePosition = Position.Top;
      targetPosition = Position.Bottom;
      iconRotation = Math.PI;
      break;
    case 'left':
      sourcePosition = Position.Right;
      targetPosition = Position.Left;
      iconRotation = (2 * Math.PI * 3) / 4;
      break;
    case 'up':
    default:
      sourcePosition = Position.Bottom;
      targetPosition = Position.Top;
      iconRotation = 0;
      break;
  }

  return (
    <>
      <NodeTemplate
        onClick={turn}
        onlyIcon={true}
        iconRotation={iconRotation}
        active={props.data.active}
        node={props}
      ></NodeTemplate>
      <Handle type="source" position={targetPosition} />
      <Handle type="target" position={sourcePosition} />
    </>
  );
};

export default LayoutNode;

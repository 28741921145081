// @ts-nocheck

import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ReactFlow,
  Node,
  Edge,
  Background,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  NodeChange,
  EdgeChange,
  addEdge,
  getOutgoers,
  useReactFlow,
  Connection,
  reconnectEdge,
  ConnectionMode,
  useUpdateNodeInternals,
} from 'reactflow';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Container from '@mui/material/Container';
import * as Y from 'yjs';

import './FlowchartEditor.css';
import 'reactflow/dist/style.css';

import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../resources/types/ReactFlowNode/ReactFlowNode';
import { FlonessNodeTypes } from '../resources/types/FlonessNode/FlonessNode';
import CustomNode from '../resources/components/CustomNode';
import CustomEdge from '../resources/components/CustomEdge';

import useNodesStateSynced from '../collaboration/useNodesStateSynced';
import useEdgesStateSynced from '../collaboration/useEdgesStateSynced';
import useCursorStateSynced from '../collaboration/useCursorStateSynced';
import Cursors from './Cursors';
import runAutoLayout from '../autolayout/runAutoLayout';
import { Dashboard } from '../api/types';
import DashboardProvider from '../collaboration/DashboardProvider';
import InterpretationNode from '../resources/components/InterpretationNode';
import AssistantNode from '../resources/components/AssistantNode';
import ProgrammableNode from '../resources/components/ProgrammableNode';
import UserNode from '../resources/components/UserNode';
import BookkeepingAccountNode from '../resources/components/BookkeepingAccountNode';
import SplitLinesNode from '../resources/components/SplitLinesNode';
import getNodeTypes from '../resources/getNodeTypes';
import useSyncedDocument from '../collaboration/useSyncedDocument';
import useUserOrigin from '../collaboration/useUserOrigin';

import { shallow } from 'zustand/shallow';
import {
  Box,
  CircularProgress,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from '@mui/material';
import useDefaultKeywordMap from '../collaboration/useDefaultKeywordMap';
import useDashboardStateSynced from '../collaboration/useDashboardStateSynced';
import useKeywordMapStateSynced from '../keyword-map/useKeywordMapStateSynced';
import useFlowMetadata from '../collaboration/useFlowMetadata';
import { _setNodes } from '../collaboration/getNodes';
import { setNodesFunction } from '../collaboration/setNodes';
import { setDefault } from '../collaboration/getDefaults';

const obj = {};
const a1 = { a: 1, data: obj };
const a2 = { a: 1, data: obj };

/*
const oldpush = window.history.pushState;


window.history.pushState = () => {
    debugger;
    try { throw new Error()} catch(e) { console.log(e.stack)}

    oldpush(...arguments)
}
const oldreplace = window.history.replaceState;
window.history.replaceState = () => {
    debugger;
    try { throw new Error()} catch(e) { console.log(e.stack)}

    oldreplace(...arguments)
}
*/

const nodeTypes = {};

const fullNodeTypes = getNodeTypes();

fullNodeTypes.forEach((nodeType) => {
  nodeTypes[nodeType.type] = nodeType.component;
});

const edgeTypes = {
  CustomEdge: CustomEdge,
};

let prevStates = null;

const defaultMetadataValue = {};

const Flow = (
  props: {
    id: string;
    currentReactFlowNodeType: ReactFlowNodeType | null;
    setCurrentReactFlowNodeType: React.Dispatch<
      React.SetStateAction<ReactFlowNodeType | null>
    >;
  },
  arg1,
  arg2,
  arg3
) => {
  // console.log("Flow.render()", arg1, arg2, arg3)

  const [flowId, setFlowId] = useState<string | null>(props.id);

  useEffect(() => {
    if (flowId != props.id) setFlowId(props.id);
  }, [props.id]);

  const [, , loaded, yDoc] = useSyncedDocument({ docId: flowId });

  const [undoManager, setUndoManager] = useState(null);

  const userOrigin = useUserOrigin();

  useEffect(() => {
    if (loaded) {
      const undoManager = new Y.UndoManager(
        [yDoc.getMap('nodes'), yDoc.getMap('edges')],
        {
          trackedOrigins: new Set([userOrigin]),
        }
      );
      setUndoManager(undoManager);

      const keyDownHandler = (event: KeyboardEvent) => {
        if (
          event.key === 'z' &&
          (event.ctrlKey || event.metaKey) &&
          event.shiftKey
        ) {
          event.preventDefault();
          event.stopPropagation();
          undoManager.redo();
        } else if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
          event.preventDefault();
          event.stopPropagation();
          undoManager.undo();
        }
      };

      document.addEventListener('keydown', keyDownHandler);
    }
  }, [loaded]);

  const [reactFlowNodes, setReactFlowNodes, onNodeChanges, nodesLoaded] =
    useNodesStateSynced({
      flowId,
    });

  const [process, setProcess, processLoaded] = useSyncedDocument({
    docId: flowId,
    type: 'process',
  });

  useEffect(() => {
    console.log('process', process);
    console.log('props.id', props.id);
  }, [process, props.id]);

  const [reactFlowEdges, setReactFlowEdges, onEdgeChanges, edgesLoaded] =
    useEdgesStateSynced({
      flowId,
    });

  const [cursors, onMouseMove] = [[], () => {}]; // useCursorStateSynced({ id: props.id });
  const [lastElements, setLastElements] = useState<{
    nodes: Node[];
    edges: Edge[];
  }>({
    nodes: reactFlowNodes,
    edges: reactFlowEdges,
  });
  const [dashboard, setDashboard] = useDashboardStateSynced();

  const [flowMetadata, setFlowMetadata, flowMetadataLoaded] = useFlowMetadata({
    flowId,
  });

  const [keywordMap, setKeywordMap] = useKeywordMapStateSynced({
    keywordMapId: flowMetadataLoaded ? flowMetadata.keywordMapId : null,
  });

  const connectingNodeId = useRef<string | null>(null);

  setDefault('nodes', reactFlowNodes, setReactFlowNodes);
  setDefault('edges', reactFlowEdges, setReactFlowEdges);
  setDefault('process', process, setProcess);
  setDefault('flowMetadata', flowMetadata, setFlowMetadata);
  setDefault('keywordMap', keywordMap, setKeywordMap);
  setDefault('dashboard', dashboard, setDashboard);

  const [viewport, setViewport] = useState({ x: 0, y: 0, zoom: 1 });

  /*
    useEffect(() => {
        if (flowMetadataLoaded) setKeywordMapId(flowMetadata?.keywordMapId);
    }, [flowMetadataLoaded]);

    useEffect(() => {
        if(!flowMetadataLoaded) return
        if (!flowMetadata) {
            setFlowMetadata({ keywordMapId });
        } else if (flowMetadata?.keywordMapId != keywordMapId) {
            setFlowMetadata({ ...flowMetadata, keywordMapId });
        }
    }, [flowMetadata?.keywordMapId, keywordMapId]);
    */

  /* useEffect(() => {
        if(processLoaded && nodesLoaded) {
            if(process?.activeNodeId) {
                reactFlowNodes.forEach(node => node.data.active = false)
                const activeNode = reactFlowNodes.find(node => node.id === process.activeNodeId)
                if(activeNode)
                    activeNode.data.active = true;
            }
        }
    }, [processLoaded, nodesLoaded, reactFlowNodes, process])
    */

  const { screenToFlowPosition, getEdges, getNodes } = useReactFlow();

  const reactFlowRef = useRef<HTMLDivElement | null>(null);

  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      console.info('ReactFlow onNodesChange');
      setReactFlowNodes((nodes) => applyNodeChanges(changes, nodes));
    },
    // setReactFlowNodes((nodes: Node[]) => applyNodeChanges(changes, nodes)),

    [setReactFlowNodes]
  );
  // const onNodesChange = useCallback(() => {}, [])

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      console.info('ReactFlow onEdgesChange');
      let nodesChanged = false;
      changes.forEach((change) => {
        if (change.type == 'remove') {
          const removedEdge = reactFlowEdges.find(
            (edge) => edge.id == change.id
          );

          const targetNode = reactFlowNodes.find(
            (node) => node.id == removedEdge?.target
          );
          if (targetNode) {
            if (targetNode.type == 'GhostNode') {
              setReactFlowNodes((nodes) =>
                nodes.filter((node) => node !== targetNode)
              );

              nodesChanged = true;
            }
          }
        }
      });

      const edges = applyEdgeChanges(changes, reactFlowEdges);

      setReactFlowEdges(edges);
    },
    [setReactFlowEdges, setReactFlowNodes, reactFlowNodes]
  );

  const onReconnect = useCallback(
    (oldEdge, newConnection) => {
      console.info('ReactFlow onReconnect');
      // connectingNodeId.current = null;

      const oldTargetNode = reactFlowNodes.find(
        (node) => node.id === oldEdge.target
      );

      if (oldTargetNode.type == 'GhostNode') {
        setReactFlowNodes((nodes) =>
          nodes.filter((node) => node.id != oldTargetNode.id)
        );
      }
      // oldEdge.target = newConnection.target;

      const edge = reactFlowEdges.find((edge) => edge.id == oldEdge.id);

      if (edge) {
        edge.target = newConnection.target;
      }

      const node = reactFlowNodes.find(
        (node) => node.id === newConnection.target
      );
      let options = [];

      if (node) {
        const fullNodeType = fullNodeTypes.find(
          (nodeType) => nodeType.type === node.type
        );
        options = fullNodeType.getDefaultOptions
          ? fullNodeType.getDefaultOptions()
          : [];
      }

      return setReactFlowEdges((edges) => {
        return [
          ...edges.filter((edge) => edge.id != oldEdge.id),
          {
            ...newConnection,
            id: `${newConnection.source}->${newConnection.target}`,
            type: 'CustomEdge',
            label: 'test',
            value: 0,
            data: {
              options,
              sourceNode: node,
            },
            reconnectable: true,
          },
        ];
      });
    },

    [reactFlowEdges, setReactFlowEdges]
  );

  const onReconnectStart = useCallback((_, { nodeId, handleType }) => {
    console.info(
      'ReactFlow onReconnectStart',
      'node.id',
      nodeId,
      'handleType',
      handleType
    );

    if (handleType === 'source') {
      connectingNodeId.current = nodeId;
    }
  }, []);

  const onConnectStart = useCallback((_, { nodeId, handleType }) => {
    console.info('ReactFlow onConnectStart', 'edge.id', nodeId);
    if (handleType === 'source') {
      connectingNodeId.current = nodeId;
    }
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      console.info(
        'ReactFlow onConnectEnd',

        'connectingNodeId.current',
        !!connectingNodeId.current
      );
      if (!connectingNodeId.current) return;
      if (!event.target.classList.contains('react-flow__pane')) return;

      const id = `ghost-${Date.now()}`;
      const newNode = {
        id,
        type: 'GhostNode',
        position: screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        }),
        data: {},
      };

      let options = [];

      const fullNodeType = fullNodeTypes.find(
        (nodeType) => nodeType.type === newNode.type
      );
      options = fullNodeType.getDefaultOptions
        ? fullNodeType.getDefaultOptions()
        : [];

      const newEdge = {
        id: `${connectingNodeId.current}->${id}`,
        type: 'CustomEdge',
        source: connectingNodeId.current,
        target: id,
        data: {
          options,
          sourceNode: newNode,
        },
        reconnectable: 'target',
      };

      setReactFlowNodes((nodes) => nodes.concat(newNode));
      setReactFlowEdges((edges) => addEdge(newEdge, edges));
      connectingNodeId.current = null;

      useUpdateNodeInternals();
    },
    [setReactFlowNodes, setReactFlowEdges, screenToFlowPosition]
  );

  const onReconnectEnd = useCallback(
    (_, oldEdge, handleType) => {
      console.info('ReactFlow onReconnectEnd', 'edge.id', oldEdge.id);
      if (handleType === 'source') {
        setReactFlowNodes((nodes) => {
          const liveNodes = nodes.filter((node) => {
            const isGhost = node.type === 'GhostNode';
            const isTarget = node.id === oldEdge.target;

            return !(isGhost && isTarget);
          });
          if (liveNodes.length < nodes.length) {
            debugger;
          }
          return liveNodes;
        });

        setReactFlowEdges((edges) =>
          edges.filter((edge) => edge.id !== oldEdge.id)
        );
      }
    },
    [setReactFlowNodes, setReactFlowEdges]
  );

  const onConnect = useCallback(
    (params: Edge | Connection) => {
      console.info('ReactFlow onConnect', 'edge.id', params.id);
      const node = reactFlowNodes.find((node) => node.id === params.source);
      connectingNodeId.current = null;
      let options = [];

      if (node) {
        const fullNodeType = fullNodeTypes.find(
          (nodeType) => nodeType.type === node.type
        );
        options = fullNodeType.getDefaultOptions
          ? fullNodeType.getDefaultOptions()
          : [];
      }

      return setReactFlowEdges(
        addEdge(
          {
            ...params,
            type: 'CustomEdge',
            label: 'test',
            value: 0,
            data: {
              options,
              sourceNode: node,
            },
            reconnectable: true,
          },
          reactFlowEdges
        )
      );
    },
    [setReactFlowEdges]
  );

  const createNewNode = (type: ReactFlowNodeType): Node => {
    const fullNodeType = fullNodeTypes.find(
      (nodeType) => nodeType.type === type
    )!;
    const node = {
      id: uuidv4(),
      type: type || ReactFlowNodeTypes.CUSTOM,
      position: { x: 0, y: 0 },
      zIndex: -1,
      ...(type === 'GroupNode' ? { width: 400, height: 150 } : {}),
      data: {
        type: props.currentReactFlowNodeType,
        label: fullNodeType.defaultLabel || 'Label',
        question: fullNodeType.defaultQuestion || 'Question',
      },
    };

    return node;
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!props.currentReactFlowNodeType) return;
    if (event.button === 0 || event.button === 1) {
      const newNode = createNewNode(props.currentReactFlowNodeType);

      newNode.position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const nodes = [...nodesLoaded, newNode];
      const sortedNodes = nodes.sort((a, b) => {
        if (a.type === 'GroupNode' && b.type !== 'GroupNode') {
          a.style = { ...a.style, zIndex: -1 };
          return -1;
        }
        if (a.type !== 'GroupNode' && b.type === 'GroupNode') {
          b.style = { ...b.style, zIndex: -1 };
          return 1;
        }
        return 0;
      });
      setReactFlowNodes(sortedNodes);
    }
    if (event.button === 0) {
      props.setCurrentReactFlowNodeType(null);
    }
  };

  useEffect(() => {
    if (
      reactFlowNodes.length === 0 ||
      (lastElements !== null &&
        _.isEqual(reactFlowNodes, lastElements.nodes) &&
        _.isEqual(reactFlowEdges, lastElements.edges))
    ) {
      return;
    }

    /* runAutoLayout(reactFlowNodes, reactFlowEdges, setReactFlowNodes, setReactFlowEdges, {
            algorithm: 'dagre',
            direction: 'TB',
            spacing: [100, 100],
        }).then((newElements) => {
            setLastElements(newElements);

            if (!dashboard || !lastElements) return;
            const index = dashboard.flows.findIndex(
                (flow) => flow.id === window.location.pathname.split('/')[2]
            );
            if (index === -1) return;
            setDashboard(
                (prevDashboard) =>
                    ({
                        ...prevDashboard,
                        flows: [
                            ...(prevDashboard?.flows.slice(0, index) || []),
                            {
                                ...prevDashboard!.flows[index],
                                dateModified: Date.now(),
                            },
                            ...(prevDashboard?.flows.slice(index + 1) || []),
                        ],
                    }) as Dashboard
            );
        });*/
  }, [reactFlowNodes, reactFlowEdges]);

  // console.log("keywordmap", keywordMap)

  const isValidConnection = useCallback(
    (connection: Connection) => {
      return true;
      const nodes = getNodes();
      const edges = getEdges();
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target!.id === connection.source) return false;
      return !hasCycle(target!);
    },
    [getNodes, getEdges]
  );

  const onDragOver = useCallback((event) => {
    console.info('ReactFlow onDragOver');
    console.log('onDragOver', reactFlowNodes);
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = (event) => {
    console.info('ReactFlow onDrop');
    console.log('onDrop', reactFlowNodes);
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    // check if the dropped element is valid
    if (typeof type === 'undefined' || !type) {
      return;
    }

    const newNode = createNewNode(type);

    newNode.position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    setReactFlowNodes((nodes) => [...(nodes || []), newNode]);
  };

  useEffect(() => {
    if (nodesLoaded) {
      setReactFlowNodes((nodes) =>
        !nodes
          ? nodes
          : nodes.map((node) => {
              if (node.id === process?.activeNodeId && !node.data.active) {
                node.data.active = true;
                node.active = true;

                // setViewport({ ...node.position, zoom: 1})
                // a bug in react flow requires the position to be changed slightly for rerendering
                node.position.x += 0.0001;
                return { ...node };
              } else if (
                node.id !== process?.activeNodeId &&
                node.data.active
              ) {
                node.data.active = false;
                node.active = false;
                // a bug in react flow requires the position to be changed slightly for rerendering
                node.position.x -= 0.0001;
                return { ...node };
              }
              return node;
            })
      );
    }
  }, [process?.activeNodeId, nodesLoaded]);

  useEffect(() => {
    if (nodesLoaded && edgesLoaded) {
      const ghostNodes = reactFlowNodes.filter(
        (node) => node.type == 'GhostNode'
      );

      const trueGhostNodes = ghostNodes.filter((ghostNode) => {
        return !reactFlowEdges.find((edge) => edge.target == ghostNode.id);
      });
      if (trueGhostNodes.length > 0) {
        setReactFlowNodes((nodes) =>
          nodes.filter((node) => !trueGhostNodes.includes(node))
        );
      }
    }
  }, [nodesLoaded, edgesLoaded]);

  const states = [
    loaded,
    yDoc,
    flowMetadata,
    setFlowMetadata,
    getEdges,
    getNodes,
    dashboard,
    setDashboard,
    process,
    nodesLoaded,
    reactFlowEdges,
    reactFlowNodes,
    setReactFlowNodes,
    setReactFlowEdges,
    lastElements,
    setLastElements,
  ];

  /*
    if (prevStates) {
        for (let i = 0; i < states.length; i++) {
            if (states[i] != prevStates[i]) console.log('variable number ', i, 'differs!');
        }
    }
    */

  prevStates = states;

  const onEdgesDelete = useCallback(
    (deletedEdges) => {
      console.info('ReactFlow onEdgesDelete');
      setReactFlowNodes((nodes) => {
        return deletedEdges.reduce(
          (acc, edge) =>
            acc.filter((n) => {
              const isGhost = n.type === 'ghost';
              const isSourceOrTarget =
                n.id === edge.source || n.id === edge.target;

              return !(isGhost && isSourceOrTarget);
            }),
          nodes
        );
      });
    },
    [setReactFlowNodes]
  );

  const [selectedEdges, setSelectedEdges] = useState<Edge[]>([]);
  const [selectedNodes, setSelectedNodes] = useState<Node[]>([]);

  return (
    <>
      {false && (
        <>
          <div>{JSON.stringify(process, undefined, 2)}</div>
          <div>{JSON.stringify(reactFlowNodes)}</div>
          <div>
            {JSON.stringify(
              reactFlowNodes.find((node) => node.id == process?.activeNodeId)
            )}
          </div>
        </>
      )}
      {loaded ? (
        <ReactFlow
          fitView
          connectionMode={ConnectionMode.Loose}
          onKeyDown={(event) => {
            if (event.keyCode == 8) {
              if (selectedEdges.length > 0) {
                const selectedEdgeTargetIds = selectedEdges.map(
                  (edge) => edge.target
                );
                debugger;

                setReactFlowNodes((nodes) =>
                  nodes.filter(
                    (node) =>
                      !selectedEdgeTargetIds.includes(node.id) ||
                      node.type != 'GhostNode'
                  )
                );

                const newEdges = reactFlowEdges.filter(
                  (edge) => edge.id !== selectedEdges[0].id
                );
                setReactFlowEdges(newEdges);
                event.stopPropagation();
              }

              if (selectedNodes.length > 0) {
                setReactFlowNodes((nodes) =>
                  nodes.filter((node) => node.id !== selectedNodes[0].id)
                );
                event.stopPropagation();
              }
            }
          }}
          // viewport={viewport}
          // onViewportChange={setViewport}
          nodes={reactFlowNodes}
          edges={reactFlowEdges}
          onClick={(event) => handleClick(event)}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={onEdgesDelete}
          ref={reactFlowRef}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onConnect={onConnect}
          onReconnectStart={onReconnectStart}
          onReconnect={onReconnect}
          onReconnectEnd={onReconnectEnd}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodeOrigin={[0.5, 0.5]}
          onPointerMove={onMouseMove}
          isValidConnection={isValidConnection}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onNodeClick={(event, node) => {
            console.log('node click', node);
            setSelectedNodes([node]);
            setSelectedEdges([]);
          }}
          onEdgeClick={(event, edge) => {
            console.log('edge click', edge);
            edge.data.shouldBeAnimated = true;
            setReactFlowEdges((edges) => [...edges]);
            setSelectedEdges([edge]);
            setSelectedNodes([]);
          }}
          onNodeDragStart={(event, node, nodes) => {
            if (node.type === 'GroupNode') {
              // Find nodes that are positioned within this group node's bounds
              const nodesWithinGroup = reactFlowNodes.filter((n) => {
                if (n.id === node.id) return false;
                if (n.type == 'GroupNode') return false;
                // Calculate group bounds relative to its center
                const groupLeft =
                  node.position.x - (node.data?.width || 200) / 2;
                const groupRight =
                  node.position.x + (node.data?.width || 200) / 2;
                const groupTop =
                  node.position.y - (node.data?.height || 200) / 2;
                const groupBottom =
                  node.position.y + (node.data?.height || 200) / 2;
                return (
                  n.position.x >= groupLeft &&
                  n.position.x <= groupRight &&
                  n.position.y >= groupTop &&
                  n.position.y <= groupBottom
                );
              });
              // Store the contained nodes and their relative positions
              node.data.containedNodes = nodesWithinGroup.map((n) => ({
                id: n.id,
                relativeX: n.position.x - node.position.x,
                relativeY: n.position.y - node.position.y,
              }));
            }
          }}
          onNodeDrag={(event, node, nodes) => {
            if (node.type === 'GroupNode' && node.data.containedNodes) {
              // Update positions of contained nodes
              setReactFlowNodes((nds) =>
                nds.map((n) => {
                  const containedNode = node.data.containedNodes.find(
                    (cn) => cn.id === n.id
                  );
                  if (containedNode) {
                    return {
                      ...n,
                      position: {
                        x: node.position.x + containedNode.relativeX,
                        y: node.position.y + containedNode.relativeY,
                      },
                    };
                  }
                  return n;
                })
              );
            }
          }}
          onNodeDragStop={(event, node, nodes) => {
            if (node.type === 'GroupNode') {
              // Clean up the stored nodes
              delete node.data.containedNodes;
            }
          }}
        >
          <Background />

          <Controls fitViewOptions={{ duration: 500 }} />
        </ReactFlow>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            justifyContent: 'center',
            alignContent: 'center',
            background: 'white',
          }}
        >
          <Stack
            spacing={4}
            direction="column"
            sx={{ margin: 'auto', paddingBottom: '200px' }}
          >
            <Skeleton
              variant="rectangular"
              width="320px"
              height="65px"
              sx={{ borderRadius: '0.5rem' }}
            />
            <Skeleton
              variant="rectangular"
              width="320px"
              height="65px"
              sx={{ borderRadius: '0.5rem' }}
            />
            <Box sx={{ position: 'relative' }}>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ position: 'absolute', left: '30px' }}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ position: 'relative', left: '250px' }}
              />
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Skeleton
                variant="rectangular"
                width="320px"
                height="65px"
                sx={{
                  borderRadius: '0.5rem',
                  position: 'absolute',
                  left: '-60%',
                }}
              />

              <Skeleton
                variant="rectangular"
                width="320px"
                height="65px"
                sx={{
                  borderRadius: '0.5rem',
                  position: 'relative',
                  left: '60%',
                }}
              />
            </Box>
            <Box sx={{ position: 'relative' }}>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ position: 'absolute', left: '30px' }}
              />
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ position: 'relative', left: '250px' }}
              />
            </Box>

            <Skeleton
              variant="rectangular"
              width="320px"
              height="65px"
              sx={{ borderRadius: '0.5rem' }}
            />
          </Stack>
        </Box>
      )}
    </>
  );
};

const FlowchartEditor = (props: {
  flowId: string;
  currentReactFlowNodeType: ReactFlowNodeType | null;
  setCurrentReactFlowNodeType: React.Dispatch<
    React.SetStateAction<ReactFlowNodeType | null>
  >;
}) => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        flexGrow: 1,
        height: '100%',
        padding: '0 !important',
        width: '100%',
        margin: '0px !important',
      }}
    >
      <Flow
        id={props.flowId}
        currentReactFlowNodeType={props.currentReactFlowNodeType}
        setCurrentReactFlowNodeType={props.setCurrentReactFlowNodeType}
      />
    </Container>
  );
};

export default FlowchartEditor;

// @ts-nocheck

interface VatSummaryItem {
  vatRate: number; // the percentage: 25.50, 24, 14, 10, 0
  taxableAmount: number; // Taxable Amount
  vatAmount: number; // VAT/MOMS amount, the total amount of MOMS tax amount for this VAT rate
  totalIncludingVAT: number; // VEROLLINEN, the total amount including tax for this VAT rate
}

interface LineItem {
  totalIncludingVAT: number; // REQUIRED, total including tax of THIS LINE ITEM. May be negative if there is a minus sign on the same line.
  vatAmount: number; // REQUIRED, the amount of tax OF THIS LINE ITEM. Has the same sign as the totalIncludingVAT of the line item. Must be 20%, 10.71%, 5.66% or 0% of the totalIncludingVAT
  expenseType: string; // the expense type of THIS LINE ITEM. must be one of the following values: ALL_KEYWORDS
  description: string; // vapaamuotoinen kuvaus lineItem
  numberOfItems: number; // REQUIRED, the number of items in the line item
  productCode?: string; // optional, if available
  unitPrice?: number; // optional, if available
}

interface VoucherData {
  content: string; // all content of the document in summarized text format
  lineItemAmounts: number[]; // an array of all line item amounts in the voucher from the right edge of the voucher as they stand in the image! They may be positive or negative
  totalIncludingVAT: number; // REQUIRED, must be the sum of totalIncludingVAT of all line items
  vatSummary: VatSummaryItem[]; // an array of all VAT tax rates in the voucher EXCLUDING THE TOTAL. DO NOT INCLUDE THE TOTAL OF ALL VAT RATES IN THE VAT SUMMARY
  lineItems: LineItem[];
  expenseType: string; // the overall expense type of the voucher. one of the following values: ALL_KEYWORDS
  description: string; // vapaamuotoinen kuvaus tositteesta
}

const voucherDataTemplate = `{
    "content": string; // all content of the document in summarized text format
    "lineItemAmounts": [number], // an array of all line item amounts in the voucher from the right edge of the voucher as they stand in the image! They may be positive or negative
    "totalIncludingVAT": // REQUIRED, must be the sum of totalIncludingVAT of all line items
    "vatSummary": [ { // an array of all VAT tax rates in the voucher EXCLUDING THE TOTAL. DO NOT INCLUDE THE TOTAL OF ALL VAT RATES IN THE VAT SUMMARY
        vatRate: number; // the percentage: 25.50, 24, 14, 10, 0
        taxableAmount: number; // Taxable Amount
        vatAmount: number; // VAT/MOMS amount, the total amount of MOMS tax amount for this VAT rate
        totalIncludingVAT: number; // VEROLLINEN, the total amount including tax for this VAT rate
    }],
    "lineItems": [
        {
            "totalIncludingVAT": // REQUIRED, total including tax of THIS LINE ITEM. May be negative if there is a minus sign on the same line.
            "vatAmount": // REQUIRED, the amount of tax OF THIS LINE ITEM. Has the same sign as the totalIncludingVAT of the line item. Must be 20%, 10.71%, 5.66% or 0% of the totalIncludingVAT
            "expenseType": string; // the expense type of THIS LINE ITEM. must be one of the following values: ALL_KEYWORDS
            "description": string; // vapaamuotoinen kuvaus lineItem
            "numberOfItems": number; // REQUIRED, the number of items in the line item
            "productCode": string; // optional, if available
            "unitPrice": number; // optional, if available
        }
    ],
    "expenseType": string; // the overall expense type of the voucher. one of the following values: ALL_KEYWORDS
    "date": string; // date in ISO format yyyy-mm-dd
    "time": string; // time in ISO format hh:mm:ss
    "description": string; // vapaamuotoinen kuvaus tositteesta SUOMEKSI IN FINNISH
    "category": string; // "expense" | "income" | "bank statement" | "other"
}`;

const getNodeTypes = () => {
  return [
    {
      type: 'FeedbackNode',
      defaultLabel: 'User Feedback',
      defaultQuestion: 'Feedback node.',
      defaultIcon: 'FeedbackIcon',
      disableOptionInput: true,
      consumeUserMessages: true,

      getAssistantInstructions: (question, options, processData) => {
        return `
                Olet taloushallinnon assistentti. Olet tehnyt seuraavat kirjanpitomerkinnät.

                ${typeof processData == 'string' ? processData : JSON.stringify(processData, null, 2)}

                Yksi tai useampi noista merkinnoista on kuitenkin väärin!

                Sinun on vastattava seuraavaan kysymykseen: 

                Mikä olisi oikea kirjanpitotili ja mikä asiasana tositteella on merkitsevä?

                Jos käyttäjä ei ole tyytyväinen, kysy häneltä lisätietoja ja kutsu funktiota add_keyword_mapping, jotta voimme parantaa vastaustamme.
                Hanki käyttäjältä seuraavat tiedot: mikä asiasana pitäisi lisätä ja mihin kirjanpitotiliin se tulisi liittää.

                HUOM! Kirjanpitotili ei käy asiasanaksi. Asiasanan täytyy olla yleiskielinen termi. Se voi viitata esimerkiksi tiettyyn liikkeeseen, toimittajan, ihmiseen tai projektiin.
                Justification ja asiasana täytyy tulla KÄYTTÄJÄLTÄ.

                ÄLÄ puhu käyttäjälle VIRHEIDEN MAHDOLLISUUDESTA.

                Kun kutsut funktiota, sinulla taytyy olla kayttajan antama perustelu sille!
                
                ÄLÄ KERRO KÄYTTÄJÄLLE FUNKTIOSTA MITÄÄN!

                ÄLÄ KYSY KÄYTTÄJÄLTÄ VAHVISTUSTA FUNKTION KUTSUMISELLE. KUTSU SITÄ ITSENÄISESTI.
                
                `;
      },
      getAssistantFunctions: (keywords) => {
        const allKeywords: string[] = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        return [
          {
            type: 'function',
            function: {
              name: 'add_keyword_mapping',
              description: `Kutsu tätä funktiota, jos käyttäjä ei ole tyytyväinen annettuun kirjanpitomerkintään. 
                                The combination must be new and unique.`,
              parameters: {
                type: 'object',
                properties: {
                  justification: {
                    type: 'string',
                    description: 'The justification for the keyword addition.',
                  },
                  keyword: {
                    type: 'string',
                    description: `the keyword to be associated with the bookkeeping account`,
                  },
                  bookkeepingAccount: {
                    type: 'number',
                    description:
                      'the number of the bookkeeping account to be associaged with the bookkeeping account. This number CANNOT be the same as in the original value.',
                  },
                },
                required: ['keyword', 'bookkeepingAccount', 'justification'],
              },
            },
          },
        ];
      },
    },
    {
      type: 'GhostNode',
      defaultLabel: 'Ghost Node',
      defaultQuestion: 'Ghost node.',
      defaultIcon: 'OpacityIcon',
      disableOptionInput: true,
    },

    {
      type: 'StartNode',
      defaultLabel: 'Input Node',
      defaultQuestion: 'Input node.',
      defaultIcon: 'StartIcon',
      disableOptionInput: true,
    },
    {
      type: 'TerminalNode',
      defaultLabel: 'Output Node',
      defaultQuestion: 'Output node.',
      defaultIcon: 'LastPageIcon',
    },
    {
      type: 'AlertNode',
      defaultLabel: 'Alert Node',
      defaultQuestion: 'Alert node.',
      defaultIcon: 'NotificationsActiveIcon',
    },
    {
      type: 'GroupNode',
      defaultLabel: 'Group Node',
      defaultQuestion: 'Group name',
      defaultIcon: 'NotificationsActiveIcon',
    },
    {
      type: 'GroupInstanceNode',
      defaultLabel: 'Group Instance Node',
      defaultQuestion: '',
      defaultIcon: 'LastPageIcon',
    },
    {
      type: 'GroupInputNode',
      defaultLabel: 'Group Input Node',
      defaultQuestion: 'Group Input node.',
      defaultIcon: 'LastPageIcon',
    },
    {
      type: 'GroupOutputNode',
      defaultLabel: 'Group Output Node',
      defaultQuestion: 'Group Output node.',
      defaultIcon: 'LastPageIcon',
    },
    {
      type: 'InterpretationNode',
      includeAlwaysInterpretation: 'true',
      consumeUserMessages: true,
      disableOptionInput: true,
      getDefaultOptions: () => {
        return [true];
      },
      defaultIcon: 'PreviewIcon',
      defaultLabel: 'Voucher Interpretation',
      defaultQuestion: 'What are the contents of the voucher?',
      useAlwaysAssistant: true,
      getImageProcessingInstructions: (keywords, additionalInstructions) => {
        const allKeywords: string[] = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        const template = `Read everything in the image. 
                 
                If there is an invoice summary, use only that summary as a source of truth.
                
                 The possible VAT percentage rates are 25%, 12%, 6% and 0%. The VAT tax amount of each lineItem must match one of those percentages. 

                JOS tosite on maksukuitti kaupasta tai ravintolasta, käytä seuraavaa ohjetta: varsinaisia lineItems ovat ne, joiden kohdalta löytyy rivikohtainen summa kuitin oikeasta reunasta. Lisäksi, ostosten ALV-kannat ja summat voi löytää useimmiten kuitin alareunasta.
                JOS ALV-koosteessa on monta eri ALV-kantaa, etsi tai laske jokaiselle lineItemille sopiva ALV-kanta kuitista.

                lineItems rivit ovat TOSITTEEN OIKEASSA REUNASSA ALLEKKAIN. Jos numeron perässä tai edessä on miinus, numero on negatiivinen! ELÄ KÄYTÄ SAMAA OIKEAN REUNAN ARVOA KAHTA KERTAA VIRHEELLISESTI.
                Jos oikeassa reunassa on numeron jälkeen - miinus merkki, se tekee numerosta negatiivisen. Jos numero on negatiivinen. se on hinnan lasku johtuen alennuksesta tai palautuksesta. Se liittyy summaa välittömästi edeltäviin riveihin.
                Jos numero on negatiivinen, myös vero on negatiivinen. Esimerkki negatiivisesta summasta, joka laskee hintaa ja on "alennus lineItem": 1.10- tai -3.49

                Päättele kunkin lineItemin ALV-kanta kuitin perusteella. Saman kuvan sisällä olevien lineItemien ALV-kannat voivat poiketa toisistaan.

                Anna numeroarvojen sijaan niiden kaava muodossa "CALC(formula)", missä formula on laskentakaava oikealle arvolle. Esimerkiksi "CALC(100/1.24)" tai "CALC(100 - 100/1.24)".  

                Jos kuitissa on PANTTI jonka arvo on alle 0.5 euroa, se on silloin pullopantti, jonka ALV-prosentti on 24%. Veron määrä lasketaan silloin kaavalla "CALC(24 / 124 * amount)".

                Output the data in the following JSON object format:
                                        
           ${voucherDataTemplate} 
           
           ${additionalInstructions || ''}`;

        const instructions = template.replace(
          /ALL_KEYWORDS/g,
          `'${allKeywords.join("' | '")}'`
        );

        return instructions;
      },

      getAssistantInstructions: (keywords) => {
        const allKeywords: string[] = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        const template = `
                        Olet assistentti, joka auttaa käyttäjää antamaan kuitin tiedot oikein. 

                        Kuitista on löydyttävä seuraavat tiedot:
${voucherDataTemplate}

Jos niitä ei löydy, sinun on kysyttävä oikeita arvoja käyttäjältä ja täydennettävä annettu objekti. 

Sitten kutsu ITSENÄISESTI finish_interpretation -toimintoa, jotta voimme jatkaa prosessia. ÄLÄ KYSY KÄYTTÄJÄLTÄ, ONKO TIEDOT OIKEIN.

JOS ASIASANAT OVAT HYVIN LÄHELLÄ OIKEAA, ELÄ KYSY KÄYTTÄJÄLTÄ MITÄÄN VAAN PÄÄTÄ ITSE.

DO NOT ASK THE USER FOR ANYTHING.
                        `;

        const instructions = template.replace(
          /ALL_KEYWORDS/g,
          `'${allKeywords.join("' | '")}'`
        );
        return instructions;
      },
      getAssistantFunctions: (keywords) => {
        const allKeywords: string[] = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        return [
          {
            type: 'function',
            function: {
              name: 'finish_interpretation',
              description:
                'Kutsu tätä funktiota, kun olet saanut oikeat tiedot käyttäjältä ja haluat jatkaa prosessia. Kaikki lineItems on oltava taydellisia.',
              parameters: {
                type: 'object',
                properties: {
                  totalIncludingVAT: {
                    type: 'number',
                    description:
                      'The total amount of the voucher including tax.',
                  },
                  expenseType: {
                    type: 'string',
                    enum: allKeywords,
                    description: `The expense type of the individual line item on the voucher`,
                  },
                  lineItems: {
                    type: 'object',
                    properties: {
                      description: {
                        type: 'string',
                        description: 'The description of the line item.',
                      },
                      expenseType: {
                        type: 'string',
                        enum: allKeywords,
                        description: `The expense type of the individual line item on the voucher`,
                      },
                      totalIncludingVAT: {
                        type: 'number',
                        description: 'The total amount of THIS LINE ITEM',
                      },
                      vatAmount: {
                        type: 'number',
                        description: 'The total tax of the line item.',
                      },
                      numberOfItems: {
                        type: 'number',
                        description: 'The number of items in the line item.',
                      },
                      productCode: {
                        type: 'string',
                        description: 'The product code of the line item.',
                      },
                      unitPrice: {
                        type: 'number',
                        description: 'The unit price of the line item.',
                      },
                    },
                    required: [
                      'description',
                      'expenseType',
                      'totalIncludingVAT',
                      'vatAmount',
                    ],
                  },
                  description: {
                    type: 'string',
                    description: 'The description of the voucher.',
                  },
                  date: {
                    type: 'string',
                    description:
                      'The ISO date string for the time of the transaction in format <YYYY>-<MM>-<DD>T<hh>:<mm>:<ss> where seconds are 00 by default',
                  },
                  category: {
                    type: 'string',
                    enum: ['expense', 'income', 'bank statement', 'other'],
                  },
                },

                required: [
                  'totalIncludingVAT',
                  'expenseType, lineItems',
                  'description',
                  'date',
                ],
              },
            },
          },
        ];
      },
    },
    {
      type: 'AssistantNode',
      consumeUserMessages: true,
      defaultLabel: 'Assistant',
      defaultQuestion: 'Define a question for the assistant to answer.',
      defaultIcon: 'AutoAwesomeIcon',
      getImageProcessingInstructions: (keywords, additionalInstructions) => {
        const allKeywords = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        const template = `Read everything in the image. 
                 
                If there is an invoice summary, use only that summary as a source of truth.
                
                 The possible VAT percentage rates are 25%, 12%, 6% and 0%. The VAT tax amount of each lineItem must match one of those percentages. 

                JOS tosite on maksukuitti kaupasta tai ravintolasta, käytä seuraavaa ohjetta: varsinaisia lineItems ovat ne, joiden kohdalta löytyy rivikohtainen summa kuitin oikeasta reunasta. Lisäksi, ostosten ALV-kannat ja summat voi löytää useimmiten kuitin alareunasta.
                JOS ALV-koosteessa on monta eri ALV-kantaa, etsi tai laske jokaiselle lineItemille sopiva ALV-kanta kuitista.

                lineItems rivit ovat TOSITTEEN OIKEASSA REUNASSA ALLEKKAIN. Jos numeron perässä tai edessä on miinus, numero on negatiivinen! ELÄ KÄYTÄ SAMAA OIKEAN REUNAN ARVOA KAHTA KERTAA VIRHEELLISESTI.
                Jos oikeassa reunassa on numeron jälkeen - miinus merkki, se tekee numerosta negatiivisen. Jos numero on negatiivinen. se on hinnan lasku johtuen alennuksesta tai palautuksesta. Se liittyy summaa välittömästi edeltäviin riveihin.
                Jos numero on negatiivinen, myös vero on negatiivinen. Esimerkki negatiivisesta summasta, joka laskee hintaa ja on "alennus lineItem": 1.10- tai -3.49

                Päättele kunkin lineItemin ALV-kanta kuitin perusteella. Saman kuvan sisällä olevien lineItemien ALV-kannat voivat poiketa toisistaan.

                Anna numeroarvojen sijaan niiden kaava muodossa "CALC(formula)", missä formula on laskentakaava oikealle arvolle. Esimerkiksi "CALC(100/1.24)" tai "CALC(100 - 100/1.24)".  

                Jos kuitissa on PANTTI jonka arvo on alle 0.5 euroa, se on silloin pullopantti, jonka ALV-prosentti on 24%. Veron määrä lasketaan silloin kaavalla "CALC(24 / 124 * amount)".

                Output the data in the following JSON object format:
                                        
           ${voucherDataTemplate} 
           
           ${additionalInstructions || ''}`;

        const instructions = template.replace(
          /ALL_KEYWORDS/g,
          `'${allKeywords.join("' | '")}'`
        );

        return instructions;
      },
      getAssistantInstructions: (
        question,
        options,
        processData,
        additionalInstructions
      ) => {
        return `
                    Olet taloushallinnon assistentti. 

                    Sinun on vastattava seuraavaan kysymykseen: 

                    ${question}

                    ${
                      Array.isArray(options)
                        ? `Vaihtoehdot, joista voit valita, ovat seuraavat:

                    ${options.join(' | ')}`
                        : ``
                    }

                    Jos nämä eivät riitä, voit kysyä lisätietoja käyttäjältä.

                    Kun vastaat lopulta kysymykseen, kutsu funktiota answer_question ja anna sille vastaus. 
                    Kutsu funktiota ENNEN kuin kerrot siitä käyttäjälle.
                    ÄLÄ KYSY KÄYTTÄJÄLTÄ VAHVISTUSTA FUNKTION KUTSUMISELLE. KUTSU SITÄ ITSENÄISESTI.
                    
                    ${additionalInstructions}
                    `;

        return `
                    Olet taloushallinnon assistentti. Sinulla on käytössäsi seuraavat pohjatiedot:

                    ${typeof processData == 'string' ? processData : JSON.stringify(processData, null, 2)}

                    Sinun on vastattava seuraavaan kysymykseen: 

                    ${question}

                    ${
                      Array.isArray(options)
                        ? `Vaihtoehdot, joista voit valita, ovat seuraavat:

                    ${options.join(' | ')}`
                        : ``
                    }

                    Jos nämä eivät riitä, voit kysyä lisätietoja käyttäjältä.

                    Kun vastaat lopulta kysymykseen, kutsu funktiota answer_question ja anna sille vastaus. 
                    Kutsu funktiota ENNEN kuin kerrot siitä käyttäjälle.
                    ÄLÄ KYSY KÄYTTÄJÄLTÄ VAHVISTUSTA FUNKTION KUTSUMISELLE. KUTSU SITÄ ITSENÄISESTI.
                    
                    `;
      },
      getAssistantFunctions: (keywords) => {
        const allKeywords = [];
        keywords.mappings.forEach((mapping) =>
          mapping.keywords.forEach((keyword) => allKeywords.push(keyword))
        );

        return [
          {
            type: 'function',
            function: {
              name: 'answer_question',
              description:
                'Kutsu tätä funktiota, kun olet saanut vastauksen selville.',
              parameters: {
                type: 'object',
                properties: {
                  answer: {
                    type: 'string',
                    description: `The answer to the question.`,
                  },
                },
                required: ['answer'],
              },
            },
          },
        ];
      },
    },
    {
      type: 'ProgrammableNode',
      defaultLabel: 'Programmable Node',
      defaultQuestion: 'Write script to determine the output.',
      defaultIcon: 'CodeIcon',
    },
    {
      type: 'KeywordMapFilterNode',
      defaultLabel: 'Keyword Account Filter',
      defaultQuestion:
        'Determine next node based on the account in the context.',
      defaultIcon: 'FilterAltIcon',
    },
    {
      type: 'KeywordMapTagNode',
      defaultLabel: 'Keyword Tag Filter',
      defaultQuestion: 'Determine next node based on the tags in the context.',
      defaultIcon: 'FilterAltIcon',
    },
    {
      type: 'UserNode',
      defaultLabel: 'User Node',
      defaultQuestion: 'Always ask the user.',
      consumeUserMessages: true,
      getAssistantInstructions: (
        question: string,
        options: string[],
        processData: any,
        additionalInstructions?: string
      ) => {
        return `
                    Olet taloushallinnon assistentti. 

                    Sinun on saatava vastaus seuraavaan kysymykseen: 

                    ${question}

                    ${
                      Array.isArray(options)
                        ? `Vaihtoehdot, joista voit valita, ovat seuraavat:

                    ${options.join(' | ')}`
                        : ``
                    }

                    Sinun on kysyttävä lisätietoja käyttäjältä.

                    Kun vastaat lopulta kysymykseen, kutsu funktiota answer_question ja anna sille vastaus. 
                    The user must not see the function name answer_question. After you have got the necessary information from the user, just call the function. 
                    
                    
                    ${additionalInstructions || ''}
                    `;
      },
      getAssistantFunctions: (keywords) => {
        return [
          {
            type: 'function',
            function: {
              name: 'answer_question',
              description:
                'Kutsu tätä funktiota, kun olet saanut vastauksen selville.',
              parameters: {
                type: 'object',
                properties: {
                  answer: {
                    type: 'string',
                    description: `The answer to the question.`,
                  },
                },
                required: ['answer'],
              },
            },
          },
        ];
      },
    },
    {
      type: 'BookkeepingAccountNode',
      defaultLabel: 'Bookkeeping Account',
      defaultQuestion: 'AI handles the node and asks the user if necessary.',
    },
    {
      type: 'KeywordMapBookkeepingAccountNode',
      defaultLabel: 'Bookkeeping Account in Context',
      defaultQuestion: 'Use the account dictated in the context',
    },
    {
      type: 'SplitLinesNode',
      defaultIcon: 'CallSplitIcon',
      defaultIconRotation: Math.PI,
      defaultLabel: 'Split Lines Start',
      defaultQuestion: 'Split the lines of the voucher.',
    },
    {
      type: 'SplitLinesEndNode',
      defaultIcon: 'CallMergeIcon',
      defaultIconRotation: Math.PI,
      defaultLabel: 'Split Lines End',
      defaultQuestion: 'Continue when all Lines are processed',
    },
    {
      type: 'LayoutNode',
      defaultLabel: 'Layout Node',
      defaultQuestion: 'Layout node.',
      defaultIcon: 'ArrowUpwardIcon',
      disableOptionInput: true,
    },
  ];
};

export default getNodeTypes;

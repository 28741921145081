// @ts-nocheck

import { Box, Button, TextField } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import getNodeTypes from '../getNodeTypes';
import { useParams } from 'react-router-dom';
import useEdgesStateSynced from '../../collaboration/useEdgesStateSynced';
import useKeywordMapStateSynced from '../../keyword-map/useKeywordMapStateSynced';
import useSyncedDocument from '../../collaboration/useSyncedDocument';
import useFlowMetadata from '../../collaboration/useFlowMetadata';
import { getNodes } from '../../collaboration/getNodes';
import defaults from '../../collaboration/getDefaults';
import CancelIcon from '@mui/icons-material/Cancel';
import iconStyle from '../../style/iconStyle';

const nodeTypes = getNodeTypes();

const CustomEdge = (props: EdgeProps) => {
  const { nodes, edges, setEdges, flowMetadata, keywordMap } = defaults;
  const [editing, setEditing] = useState(props.editing);

  const [sourceNode, setSourceNode] = useState(
    nodes.find((node) => node.id == props.source)
  );

  useEffect(() => {
    setSourceNode(nodes.find((node) => node.id == props.source));
  }, [props.source, nodes.find((node) => node.id == props.source)?.type]);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    targetX: props.targetX,
    targetY: props.targetY,
    sourcePosition: props.sourcePosition,
    targetPosition: props.targetPosition,
  });

  useEffect(() => {
    const edge = edges.find((edge) => edge.id == props.id);

    if (edges[edges.length - 1] != edge && editing) {
      console.log('setting edge to last');
      setEdges([...edges.filter((e) => e.id != props.id), edge]);
    }
  }, [editing]);

  const sourceNodeType = sourceNode?.type;
  // props.data?.sourceNode?.type
  const fullNodeType = nodeTypes.find(
    (nodeType) => nodeType.type == sourceNodeType
  );

  const optionInput = useMemo(() => {
    if (!editing) return null;
    try {
      // const sourceNode = (nodes || []).find((node) => node.id == props?.source);
      //             const sourceNodeType = sourceNode?.type;
      // props.data?.sourceNode?.type
      //          const fullNodeType = nodeTypes.find((nodeType) => nodeType.type == sourceNodeType);
      // if(!fullNodeType) {debugger}
      if (!fullNodeType.getOptionInput) throw new Error('No getOptionInput');
      return fullNodeType.getOptionInput({
        onClose: () => setEditing(false),
        node: sourceNode,
        edge: { ...props },
        edges,
        keywordMap,
        onChange: () => {
          setEdges([...edges]);
        },
      });
    } catch (error) {
      console.error(error);
      return <div>input2</div>;
    }
  }, [
    (edges || []).find((edge) => edge.id == props.id)?.data?.options?.length,

    sourceNode,
    editing,
  ]);

  const optionDisplay = useMemo(() => {
    if (editing) return null;
    try {
      //            const sourceNode = (nodes || []).find((node) => node.id == props?.source);
      // if(!fullNodeType) {debugger}
      if (!fullNodeType.getOptionDisplay) throw new Error('No getOptionInput');
      return fullNodeType.getOptionDisplay({
        node: sourceNode,
        edge: { ...props },
        edges,
        keywordMap,
        onChange: () => {
          setEdges([...edges]);
        },
      });
    } catch (error) {
      console.log(error);
      return <div>input2</div>;
    }
  }, [props.source, sourceNode, editing]);

  const [value, setValue] = useState(props.value);

  return (
    <>
      <svg style={{ overflow: 'display' }}>
        <defs>
          <marker
            id="arrow"
            viewBox="0 0 115 115"
            refX="57.5"
            refY="57.5"
            markerWidth="6"
            markerHeight="6"
            orient="auto"
          >
            <path
              d="M 50,57.5 10,85 10,30 z"
              style={{
                fill: '#bbbbbb',
                stroke: '#bbbbbb',
                strokeLinejoin: 'round',
                strokeLinecap: 'round',
                strokeWidth: 20,
              }}
            />
          </marker>
        </defs>
      </svg>

      <BaseEdge
        labelStyle={{ fill: '#bbbbbb', fontWeight: 700 }}
        labelShowBg={true}
        labelX={labelX}
        labelY={labelY}
        path={edgePath}
        markerEnd={'url(#arrow)'}
        style={{ ...props.style, strokeWidth: 2, overflow: 'display' }}
      ></BaseEdge>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
          }}
          className="nodrag nopan"
        >
          <Box
            sx={{
              // pointerEvents: "all",
              position: 'relative',
            }}
          >
            {editing ? (
              <Box sx={{ minWidth: '20px' }}>
                {optionInput}
                {fullNodeType.disableOptionInput ? null : (
                  <Box
                    sx={{ position: 'absolute', right: '-11px', top: '-8px' }}
                  >
                    <CancelIcon
                      color={'info'}
                      fontSize={'medium'}
                      sx={iconStyle}
                      onClick={() => setEditing(false)}
                    ></CancelIcon>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{ minWidth: '20px', cursor: 'pointer' }}
                onClick={() => setEditing(true)}
              >
                {optionDisplay}
              </Box>
            )}
          </Box>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;

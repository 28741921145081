export async function saveToS3(
  data: unknown,
  path: string,
  organizationId: string
) {
  await fetch('/save-yjs/' + path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Organization': organizationId,
    },
    body: JSON.stringify(data),
  });
}

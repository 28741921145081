import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StartIcon from '@mui/icons-material/Start';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LastPageIcon from '@mui/icons-material/LastPage';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import PreviewIcon from '@mui/icons-material/Preview';
import CodeIcon from '@mui/icons-material/Code';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FeedbackIcon from '@mui/icons-material/Feedback';
import OutputIcon from '@mui/icons-material/Output';

const getIconClass = (icon: string) => {
  switch (icon) {
    case 'OutputIcon':
      return OutputIcon;
    case 'FeedbackIcon':
      return FeedbackIcon;
    case 'FilterAltIcon':
      return FilterAltIcon;
    case 'CallMergeIcon':
      return CallMergeIcon;
    case 'CallSplitIcon':
      return CallSplitIcon;
    case 'ArrowUpwardIcon':
      return ArrowUpwardIcon;
    case 'CodeIcon':
      return CodeIcon;
    case 'AutoAwesomeIcon':
      return AutoAwesomeIcon;
    case 'ErrorIcon':
      return ErrorIcon;
    case 'CheckCircleIcon':
      return CheckCircleIcon;
    case 'StartIcon':
      return StartIcon;
    case 'LastPageIcon':
      return LastPageIcon;
    case 'NotificationsActiveIcon':
      return NotificationsActiveIcon;
    case 'CalendarViewMonthIcon':
      return CalendarViewMonthIcon;
    case 'PreviewIcon':
      return PreviewIcon;
    default:
      return QuestionMarkIcon;
  }
};

export default getIconClass;

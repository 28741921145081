// @ts-nocheck

import {
  addEdge,
  useReactFlow,
  Handle,
  Position,
  reconnectEdge,
} from 'reactflow';

export const GhostNode = () => (
  <div style={{ width: 5, height: 5 }}>
    <Handle
      type="target"
      position={Position.Top}
      isConnectable={false}
      style={{ background: '#aaa' }}
    />
  </div>
);

export default GhostNode;

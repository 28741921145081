import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Chip, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import HeaderBar, { CurrentPages } from '../header-bar/HeaderBar';
import useActiveOrganisation from '../collaboration/useActiveOrganisation';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  deserializeChartOfAccounts,
  ChartOfAccounts,
  serializeAccount,
} from '@floness/accounting/browser';
import ChartOfAccountsList from './ChartOfAccountsList';

interface Customer {
  customerId: string;
  customerName: string;
  tags: string[];
}

const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  const [reports, setReports] = useState<ChartOfAccounts>({
    tree: [],
    list: [],
  });
  const [activeOrganisation] = useActiveOrganisation();
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [expandedAccounts, setExpandedAccounts] = useState<Set<string>>(
    new Set()
  );

  const toggleExpand = (accountId: string) => {
    setExpandedAccounts((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(accountId)) {
        newSet.delete(accountId);
      } else {
        newSet.add(accountId);
      }
      return newSet;
    });
  };

  useEffect(() => {
    const fetchReports = async () => {
      if (isLoading || loaded) return;

      setIsLoading(true);
      try {
        const response = await fetch('/api/accounting/reports', {
          headers: {
            'X-Organization': activeOrganisation.organizationId,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch reports');
        }
        const data = await response.json();

        const { list, tree } = deserializeChartOfAccounts(data);
        setReports({ list, tree });
        setLoaded(true);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReports();
  }, [activeOrganisation]);

  return (
    <>
      <HeaderBar currentPage={CurrentPages.Reports} setCurrentPage={() => {}} />
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('Reports')}
        </Typography>
        <ChartOfAccountsList
          accounts={reports.tree}
          expandedAccounts={expandedAccounts}
          toggleExpand={toggleExpand}
          handleDrop={null}
        />
      </Box>
    </>
  );
};

export default ReportsPage;

// @ts-nocheck

import { memo, useState } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import {
  ReactFlowNodeType,
  ReactFlowNodeTypes,
} from '../types/ReactFlowNode/ReactFlowNode';
import TopicIcon from '@mui/icons-material/Topic';
import NodeTemplate from './NodeTemplate';
import QuestionNode from './QuestionNode';
import { Box, Select, MenuItem, Typography, FormControl } from '@mui/material';
import useNodesStateSynced from '../../collaboration/useNodesStateSynced';
import { useParams } from 'react-router-dom';

interface CustomNodeProps extends NodeProps {
  data: { label: string; type: ReactFlowNodeType; question?: string };
}

const GroupInstanceNode = (props: CustomNodeProps) => {
  const { flowId } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const [reactFlowNodes, setReactFlowNodes, onNodeChanges, nodesLoaded] =
    useNodesStateSynced({
      flowId,
    });

  const groupNodes = reactFlowNodes.filter((node) => node.type === 'GroupNode');
  console.log(groupNodes.map((n) => n.data));

  const handleSelectClick = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const handleMenuItemClick = (value) => (event) => {
    event.stopPropagation();
    props.data.groupId = value;
    setReactFlowNodes([...reactFlowNodes]);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <NodeTemplate active={props.data.active} node={props}>
        <Box style={{ width: 400, height: 'auto', position: 'relative' }}>
          <FormControl>
            <Select
              fullWidth
              value={props.data.groupId || ''}
              onClick={handleSelectClick}
              onClose={handleClose}
              variant="outlined"
              displayEmpty
              open={isOpen}
              sx={{ minWidth: 120 }}
            >
              <MenuItem value="" disabled>
                Select a group
              </MenuItem>
              {groupNodes &&
                groupNodes.map((node) => (
                  <MenuItem
                    key={node.id}
                    value={node.id}
                    onClick={handleMenuItemClick(node.id)}
                  >
                    {node.data.label || 'Untitled Group'}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </NodeTemplate>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </>
  );
};

export default GroupInstanceNode;

// @ts-nocheck

import AlertNode from './components/AlertNode.js';
import AssistantNode from './components/AssistantNode.js';
import BookkeepingAccountNode from './components/BookkeepingAccountNode.js';
import CustomNode from './components/CustomNode.js';

import InterpretationNode from './components/InterpretationNode.js';
import KeywordMapBookkeepingAccountNode from './components/KeywordMapBookkeepingAccountNode.js';
import KeywordMapFilterNode from './components/KeywordMapFilterNode.js';
import ProgrammableNode from './components/ProgrammableNode.js';
import SplitLinesNode from './components/SplitLinesNode.js';
import StartNode from './components/StartNode.js';
import TerminalNode from './components/TerminalNode.js';
import UserNode from './components/UserNode.js';
import LayoutNode from './components/LayoutNode.js';
import GhostNode from './components/GhostNode.js';
import FeedbackNode from './components/FeedbackNode.js';
import GroupNode from './components/GroupNode.tsx';
import GroupInputNode from './components/GroupInputNode.tsx';
import GroupOutputNode from './components/GroupOutputNode.tsx';
import GroupInstanceNode from './components/GroupInstanceNode.tsx';

import ReactFlowNodeTypes from './types/ReactFlowNode/ReactFlowNodeTypes.js';

import getCommonNodeTypes from '../../../common/getNodeTypes.js';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';

const availableClasses = {
  GhostNode: GhostNode,
  FeedbackNode: FeedbackNode,
  LayoutNode: LayoutNode,
  AlertNode: AlertNode,
  GroupNode: GroupNode,
  AssistantNode: AssistantNode,
  BookkeepingAccountNode: BookkeepingAccountNode,
  InterpretationNode: InterpretationNode,
  KeywordMapBookkeepingAccountNode: KeywordMapBookkeepingAccountNode,
  KeywordMapFilterNode: KeywordMapFilterNode,
  KeywordMapTagNode: KeywordMapTagNode,
  ProgrammableNode: ProgrammableNode,
  SplitLinesNode: SplitLinesNode,
  StartNode: StartNode,
  TerminalNode: TerminalNode,
  UserNode: UserNode,
  SplitLinesEndNode: SplitLinesEndNode,
  GroupInputNode: GroupInputNode,
  GroupOutputNode: GroupOutputNode,
  GroupInstanceNode: GroupInstanceNode,
};
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import useNodesStateSynced from '../collaboration/useNodesStateSynced.js';
import useEdgesStateSynced from '../collaboration/useEdgesStateSynced.js';
import KeywordMapTagNode from './components/KeywordMapTagNode.js';
import { useTranslation } from 'react-i18next';
import { set } from 'lodash';
import SplitLinesEndNode from './components/SplitLinesEndNode.js';

const iconStyle = {
  background: 'white',
  padding: '1px',
  cursor: 'pointer',
  borderRadius: '20px',
  position: 'relative',
  top: '7px',
  marginTop: '-10px',
};
const boxStyle = {
  border: 'solid 2px #ddd',
  background: 'white',
  borderRadius: '20px',
  paddingLeft: '6px',
  paddingRight: '12px',
  paddingTop: '8px',
  paddingBottom: '8px',
  justifyContent: 'center',
};

const chipStyle = {
  marginRight: '8px',
  position: 'relative',
  top: '4px',
  marginBottom: '8px',
};

const inputBoxStyle = Object.assign({}, boxStyle, {
  // position: "relative",
  //bottom: "-10px",
  zIndex: 9999,
  minWidth: '220px',
  left: '0px',
  right: '0px',
  maxWidth: '320px',
  paddingTop: '4px',
});

const CheckmarkOptionDisplay = ({ edge }) => {
  const { flowId } = useParams();
  const [edges, setEdges] = useEdgesStateSynced({ flowId });

  return (
    <Box
      sx={boxStyle}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();

        if ((edge.data?.options || []).includes(true)) {
          edge.data.options = [false];
        } else {
          edge.data.options = [true];
        }
        setEdges([...edges]);
      }}
    >
      {(edge.data?.options || []).includes(true) ? (
        <CheckCircleIcon
          sx={{ ...iconStyle, marginRight: '-8px', marginLeft: '-1px' }}
          fontSize={'medium'}
          color="success"
        ></CheckCircleIcon>
      ) : (
        <ErrorIcon
          sx={{ ...iconStyle, marginRight: '-8px', marginLeft: '-1px' }}
          fontSize={'medium'}
          color="warning"
        />
      )}
    </Box>
  );
};

const FreeTextOptionsDisplay = ({ edge }) => {
  const { t } = useTranslation();

  const fixedOptions = [
    { label: 'Any bookkeeping account', value: 'account.*' },
    { label: 'Any keyword', value: 'keyword.*' },
    { label: 'Any', value: '*' },
    { label: 'None', value: null },
  ];

  if (edge.data?.options?.length > 0) {
    return (
      <Box sx={{ ...boxStyle, maxWidth: '320px' }}>
        <CheckCircleIcon
          sx={iconStyle}
          fontSize={'medium'}
          color="success"
        ></CheckCircleIcon>
        <Typography sx={{ display: 'inline' }} variant="body2">
          {' '}
          {edge.data.options
            .map((option) => {
              const fixedOption = fixedOptions.find(
                (fixedOption) => fixedOption.value == option
              );

              if (fixedOption) return t(fixedOption.label);
              return option;
            })
            .join(', ')}
        </Typography>
      </Box>
    );
  } else {
    return (
      <ErrorIcon sx={iconStyle} fontSize={'medium'} color="error"></ErrorIcon>
    );
  }
};

const StoreValueDisplay = ({ edge, node }) => {
  const nodeTypes = getNodeTypes();
  const nodeType = nodeTypes.find((type) => type.type === node.type);

  return (
    <Box>
      <Typography variant="body2">
        {' '}
        {edge.data.storedProperties || 'test'}
      </Typography>
    </Box>
  );
};

const optionDisplays = {
  StartNode: () => <div></div>,
  LayoutNode: ({ edge }) => <div />,
  ProgrammableNode: ({ edge }) => (
    <FreeTextOptionsDisplay edge={edge}></FreeTextOptionsDisplay>
  ),
  InterpretationNode: ({ edge }) => <CheckmarkOptionDisplay edge={edge} />,
  KeywordMapTagNode: ({ edge, node }) => {
    return (
      <>
        <FreeTextOptionsDisplay edge={edge}></FreeTextOptionsDisplay>
      </>
    );
  },

  KeywordMapFilterNode: ({ edge, node }) => {
    return (
      <>
        <FreeTextOptionsDisplay edge={edge}></FreeTextOptionsDisplay>
      </>
    );
    // return (edge.data?.options?.length > 0 ? edge.data.options : ['Undefined']).join(', ');
  },
  AssistantNode: ({ edge }) => (
    <FreeTextOptionsDisplay edge={edge}></FreeTextOptionsDisplay>
  ),
  UserNode: ({ edge }) => (
    <FreeTextOptionsDisplay edge={edge}></FreeTextOptionsDisplay>
  ),
};

const CodeBoxInput = ({
  children,
  node,
  edge,
  keywordMap,
  onChange,
  onClose,
}) => {
  const [editingCode, setEditingCode] = useState(false);

  const { flowId } = useParams();
  const [nodes, setNodes] = useNodesStateSynced({ flowId });
  const [edges, setEdges] = useEdgesStateSynced({ flowId });

  return (
    <Box sx={inputBoxStyle}>
      {...children}

      {editingCode ? (
        <Box
          sx={
            (children || []).length > 0
              ? {
                  borderTop: 'solid 1px lightgrey',
                  marginTop: '8px',
                  paddingTop: '8px',
                }
              : {}
          }
        >
          <Typography
            variant="body2"
            sx={{ fontSize: '10px', fontFamily: 'Courier New' }}
          >
            {`function processOption({ data, nodes, edges }) {`}
          </Typography>
          <TextField
            multiline
            autoFocus={true}
            onClick={(event) => {
              event.stopPropagation();
            }}
            inputProps={{
              style: {
                fontSize: '10px',
                lineHeight: '12px',
                fontFamily: 'Courier New',
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: 'none',
                },
              },
              marginLeft: '20px',
              padding: 0,
              width: 'calc(100% - 20px)',
              fontFamily: 'Courier New',
            }}
            size={'small'}
            value={edge.data.code}
            variant="outlined"
            onChange={(event) => {
              edge.data.code = event.target.value;
              // setNewOption(event.target.value);

              setEdges([...edges]);
            }}
          ></TextField>
          <Typography
            variant="body2"
            sx={{ fontSize: '10px', fontFamily: 'Courier New' }}
          >
            {`}`}
          </Typography>

          <Typography
            variant="body2"
            onClick={() => {
              setEditingCode(false);
            }}
            sx={{ textAlign: 'right' }}
            color="primary"
          >
            Hide code
          </Typography>
        </Box>
      ) : (
        <Typography
          variant="body2"
          onClick={() => {
            setEditingCode(true);
          }}
          sx={{ textAlign: 'right' }}
          color="primary"
        >
          Edit code
        </Typography>
      )}
    </Box>
  );
};

const FreeTextOptionsInput = ({
  node,
  edge,
  keywordMap,
  onChange,
  onClose,
}) => {
  const [newOption, setNewOption] = useState('');
  const { t } = useTranslation();

  const fixedOptions = [
    { label: 'Any keyword', value: 'keyword.*' },
    { label: 'Any bookkeeping account', value: 'account.*' },
  ];

  return (
    <CodeBoxInput
      node={node}
      edge={edge}
      keywordMap={keywordMap}
      onChange={onChange}
      onClose={onClose}
    >
      <Box sx={{ minWidth: '200px', marginBottom: '4px' }}>
        {fixedOptions.map((option, optionIndex) => {
          return (
            <Chip
              key={`fixed-option-${edge.id}-${optionIndex}`}
              onClick={(event) => {
                event.stopPropagation();
                if (!edge.data.options) edge.data.options = [];
                const active = edge.data.options.includes(option.value);

                if (active) {
                  const index = edge.data.options.indexOf(option.value);
                  edge.data.options.splice(index, 1);
                } else {
                  edge.data.options.push(option.value);
                }
                onChange();
              }}
              sx={chipStyle}
              label={t(option.label)}
              color={
                edge.data.options.includes(option.value) ? 'primary' : 'default'
              }
            ></Chip>
          );
        })}

        {(edge.data?.options || [])
          .filter(
            (option) =>
              !fixedOptions.find((fixedOption) => fixedOption.value == option)
          )
          .map((option, index) => (
            <Chip
              color={'primary'}
              sx={chipStyle}
              key={`chip_freetext_${edge.id || edge.guid}_${index}`}
              label={option}
              onDelete={() => {
                const index = edge.data.options.indexOf(option);
                edge.data.options.splice(index, 1);

                onChange();
              }}
            ></Chip>
          ))}
        {(edge.data?.options?.length || []) == 0 && (
          <Typography
            sx={{ paddingTop: '9px', paddingBottom: '10px' }}
            color="#ddd"
            variant="body2"
          >
            <ErrorIcon
              sx={iconStyle}
              fontSize={'medium'}
              color="disabled"
            ></ErrorIcon>
            No options
          </Typography>
        )}
      </Box>
      <TextField
        autoFocus={true}
        onClick={(event) => {
          event.stopPropagation();
        }}
        /*inputProps={{
                    onKeyDown: (event) => {
                        event.stopPropagation();
                    },
                }}*/
        sx={{ width: '100%' }}
        size={'small'}
        value={newOption}
        variant="outlined"
        onChange={(event) => {
          setNewOption(event.target.value);
        }}
        onKeyUp={(event) => {
          event.stopPropagation();
        }}
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.keyCode == 13) {
            if (!edge.data.options) edge.data.options = [];
            if (!edge.data.options.find((option) => option == newOption))
              edge.data.options.push(newOption);
            setNewOption('');

            onChange();
          }
        }}
      ></TextField>
      <Breadcrumbs></Breadcrumbs>
    </CodeBoxInput>
  );
};

const EnumeratedToggleOptionsInput = ({
  allowNull,
  options,
  node,
  edge,
  keywordMap,
  onChange,
  onClose,
}) => {
  const { t } = useTranslation();
  const [prefix, setPrefix] = useState('');

  return (
    <Box sx={inputBoxStyle}>
      {allowNull && (
        <>
          <Chip
            onClick={(event) => {
              event.stopPropagation();
              if (!edge.data.options) edge.data.options = [];
              const active = edge.data.options.includes(null);

              if (active) {
                const index = edge.data.options.indexOf(null);
                edge.data.options.splice(index, 1);
              } else {
                edge.data.options.push(null);
              }
              onChange();
            }}
            sx={chipStyle}
            label={t('None')}
            color={edge.data.options.includes(null) ? 'primary' : 'default'}
          ></Chip>
          <Chip
            onClick={(event) => {
              event.stopPropagation();
              if (!edge.data.options) edge.data.options = [];
              const active = edge.data.options.includes('*');

              if (active) {
                const index = edge.data.options.indexOf('*');
                edge.data.options.splice(index, 1);
              } else {
                edge.data.options.push('*');
              }
              onChange();
            }}
            sx={chipStyle}
            label={t('Any')}
            color={edge.data.options.includes('*') ? 'primary' : 'default'}
          ></Chip>
        </>
      )}
      {options.map((option, optionIndex) => {
        return (
          <Chip
            key={`chip_key_edge-${edge.id}_mapping-${optionIndex}`}
            onClick={(event) => {
              event.stopPropagation();

              if (!edge.data.options) edge.data.options = [];

              const active = edge.data.options.includes(option);

              if (active) {
                const index = edge.data.options.indexOf(option);
                edge.data.options.splice(index, 1);
              } else {
                edge.data.options.push(option);
              }

              onChange();
            }}
            sx={chipStyle}
            label={option}
            color={
              edge.data.options?.includes(option)
                ? 'primary'
                : prefix.length > 0 && option.startsWith(prefix)
                  ? 'warning'
                  : 'default'
            }
          ></Chip>
        );
      })}

      <TextField
        onBlur={() => setPrefix('')}
        value={prefix}
        onChange={(event) => {
          setPrefix(event.target.value);
          console.log('set prefix');
        }}
        onKeyDown={(event) => {
          event.stopPropagation();

          if (event.keyCode == 13) {
            options.forEach((option, optionIndex) => {
              if (!option.startsWith(prefix)) {
                return;
              }
              const active = edge.data.options.includes(option);

              if (!active) {
                edge.data.options.push(option);
              } else {
              }
            });
            onChange();
            setPrefix('');
          }
        }}
        autoFocus={true}
        sx={{ position: 'fixed', top: '-1000px' }}
      ></TextField>
    </Box>
  );
};

const optionInputs = {
  ProgrammableNode: ({ node, edge, keywordMap, onChange, onClose }) => {
    return (
      <FreeTextOptionsInput
        node={node}
        edge={edge}
        keywordMap={keywordMap}
        onChange={onChange}
        onClose={onClose}
      ></FreeTextOptionsInput>
    );
  },

  InterpretationNode: ({ edge }) => <CheckmarkOptionDisplay edge={edge} />,
  UserNode: ({ node, edge, keywordMap, onChange, onClose }) => {
    return (
      <FreeTextOptionsInput
        node={node}
        edge={edge}
        keywordMap={keywordMap}
        onChange={onChange}
        onClose={onClose}
      ></FreeTextOptionsInput>
    );
  },

  AssistantNode: ({ node, edge, keywordMap, onChange, onClose }) => {
    return (
      <FreeTextOptionsInput
        node={node}
        edge={edge}
        keywordMap={keywordMap}
        onChange={onChange}
        onClose={onClose}
      ></FreeTextOptionsInput>
    );
  },
  KeywordMapTagNode: (params) => {
    const { node, edge, keywordMap, onChange, onClose } = params;
    const allTags = [];
    keywordMap.mappings.forEach((mapping, mappingIndex) => {
      const tags = mapping.tags || [];

      tags.forEach((tag) => {
        if (!allTags.includes(tag)) {
          allTags.push(tag);
        }
      });
    });

    return (
      <EnumeratedToggleOptionsInput
        allowNull={true}
        {...params}
        options={allTags}
      />
    );
  },
  KeywordMapFilterNode: (params) => {
    const { node, edge, keywordMap, onChange, onClose } = params;
    const options = keywordMap.mappings
      .filter(
        (mapping) =>
          mapping.bookkeepingAccounts.length == 1 &&
          mapping.bookkeepingAccounts[0]
      )
      .map((mapping, mappingIndex) => {
        const label = `${mapping.bookkeepingAccounts[0].accountNumber} ${mapping.bookkeepingAccounts[0].accountName}`;
        return label;
      });

    return (
      <EnumeratedToggleOptionsInput
        allowNull={true}
        {...params}
        options={options}
      />
    );
  },
};

const getNodeTypes = () => {
  return getCommonNodeTypes().map((nodeType) => {
    const nodeClass = availableClasses[nodeType.type];
    // availableClasses.find(nodeClass => nodeClass.name === nodeType.type)

    let getOptionInput = () => <div>input22</div>;
    let getOptionDisplay = ({ edge }) => (
      <div>{JSON.stringify(edge.data?.options)}</div>
    );

    if (optionInputs[nodeType.type]) {
      getOptionInput = optionInputs[nodeType.type];
    }

    if (optionDisplays[nodeType.type]) {
      getOptionDisplay = optionDisplays[nodeType.type];
    }

    return {
      ...nodeType,
      component: nodeClass,
      getOptionInput,
      getOptionDisplay,
    };
  });
};

export default getNodeTypes;
